import React           from 'react';
import hoistStatics    from "hoist-non-react-statics";
import { get }         from "lodash";
import uuid            from "uuid/v1";

import { GAME_NAME }   from '../translations';
import { StvAppConnectorLite as StvAppConnector } from '../utils/iframe';

const IframeContext = React.createContext();
IframeContext.displayName = "Iframe context";

export let logError = () => () => {}
export let log = (type, data) => {}

export class IframeProvider extends React.Component {
  app = null;
  state = {
    ready: false,
    config: {
      info: {
        // vendor: 'oms'
        vendor: 'browser'
      }
    }
  }
  constructor(props) {
    super(props);
    this.app = new StvAppConnector({
      name: GAME_NAME,
      env: props.env,
      version: props.version
    });
    this.app.setLanguage(this.app.kibana.lang || 'en');
    logError  = this.sendError.bind(this);
    log       = this.sendLog.bind(this);
  }

  componentDidMount () {
    // check if standalone
    if (window.parent === window) return this.setState({ ready: true })
    // or iframe
    this.app.on('init', config => {
      this.app.send('start');
      this.setState({ ready: true, config })
    });

    this.app.send('loaded'); // ping stv app
  }

  exitApp = () => {
    this.app.send('back');
  }

  sendLog = (type, data) => {
    console.log('kibana:log', type, data)
    this.app.sendKibana(type, data)
  }

  sendError = (prefix, err, payload = {}) => {
    console.log('kibana:error', prefix, err)
    this.app.sendError(prefix, err, payload)
  }

  getKeycodes = () => get(this.state.config, 'keys', null);
  getDeviceInfo = () => get(this.state.config, 'info', null);

  getGeo = () => {
    if (this.state.config.geo && this.state.config.geo === 'ru') return 'ru';
    return 'default';
  }

  getDid = () => {
    const storedDid = window.localStorage.getItem('did');
    const newDid    = get(this.getDeviceInfo(), 'deviceID', uuid());

    if (newDid !== storedDid) {
      window.localStorage.setItem('did', newDid)
      return window.localStorage.getItem('did');
    }

    return newDid || storedDid
  }

  saveSession = (id) => {
    window.localStorage.setItem('gameId', id);
  }

  restoreSession = (fn) => {
    return fn(window.localStorage.getItem('gameId'));
  }

  removeSession = () => {
    window.localStorage.removeItem('gameId');
  }

  saveUsedDecks = (values) => {
    window.localStorage.setItem('usedDecks', JSON.stringify(values));
  }

  getUsedDecks = (values) => {
    try {
      return JSON.parse(window.localStorage.getItem('usedDecks'));
    } catch (e) {
      return [];
    }
  }

  render () {
    if (!this.state.ready) return null;
    return <IframeContext.Provider children={this.props.children || null} value={{
      exitApp: this.exitApp,
      sendError: this.sendError,
      sendLog: this.sendLog,
      locale: this.app.kibana.lang || 'en',
      geo: this.getGeo(),
      did: this.getDid(),
      getKeycodes: this.getKeycodes,
      getDeviceInfo: this.getDeviceInfo,
      saveSession: this.saveSession,
      restoreSession: this.restoreSession,
      removeSession: this.removeSession,
      saveUsedDecks: this.saveUsedDecks,
      getUsedDecks: this.getUsedDecks,
    }}/>
  }
}


export const withIframe = (Component) => {
  const displayName = `withIframe(${Component.displayName || Component.name})`;
  const C = props => {
  const { wrappedComponentRef, ...remainingProps } = props;
    return (
      <IframeContext.Consumer>
      {
        context => (<Component
        {...remainingProps}
        {...context}
        ref={wrappedComponentRef}
        />)
      }
      </IframeContext.Consumer>
    );
  };
  C.displayName = displayName;
  C.WrappedComponent = Component;
  return hoistStatics(C, Component);
}

