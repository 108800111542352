import React           from 'react';
import { connect }     from 'react-redux';
import cs              from 'classnames';

import { MOBILE_URL_GEO } from '../constants';

import {
  get,
  clamp,
  times
} from 'lodash';

import {
  withFocus,
  FOCUS_ITEM,
  FOCUS_ACTIONS
} from './Focus';

import { RoundedButton } from './Buttons';

import {
  fromOutside,
  fadeInTimeline,
  shrink,
  appearence
} from './animations/HowToPlay';

import { play, reverse, create, timeline, kill, isAnimated } from './animations';

import { histTimeline } from './animations/Histogram';

import { withIframe } from './Iframe'

import { setVisibleHtp } from '../store/reducers/ui';

import { compareFalseToTrue } from '../utils/game';

import { i18n as lang } from '../translations';

import { Splash } from './Splash';


const replaceMobileUrl = (str, url) => str.replace('<%=MOBILE_URL%>', url)

export const DEFAULT_CARDS = [
  require('../img/cards/1_city.jpg'),
  require('../img/cards/2_fish_from_another_district.jpg'),
  require('../img/cards/3_super_idea.jpg'),
  require('../img/cards/4_girl_with_ear_flower.jpg'),
  require('../img/cards/5_snow_toy.jpg')
];

const HowToPlayPages = {
  MAIN: 'main',
  START: 'start',
  ROUND: 'round',
  CARDS: 'cards',
  RESULTS: 'results',
  SCORE: 'score',
  GAME_OVER: 'game-over',
  FAQ: 'faq',
}

const pages = Object.keys(HowToPlayPages);

class Page extends React.Component {
  static displayName = 'HowToPlay'

  state = {
    currentPage: 0,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      return this.postAnimation();
    }

    // emulate component did mount
    if (compareFalseToTrue(prevProps.visible, this.props.visible)) {
      this.postAnimation().then(() => {
        this.props.setActiveComponent(this);
      })
    }
  }

  nextPage = async (dir = 1) => {
    const currentPage = clamp(this.state.currentPage + dir, 0, pages.length - 1)
    if (currentPage === this.state.currentPage) return;
    await this.preAnimation();
    this.setState({currentPage});
  }

  prevPage = () => this.nextPage(-1);

  navigateBack = async () => {
    this.props.removeActiveComponent();
    await this.preAnimation();
    kill(this.currentTimeline);
    this.setState({currentPage: 0});
    this.props.close();
  }

  preAnimation = () => {
    return reverse(this.currentTimeline, 2.5)
  }

  postAnimation = () => {
    const page = get(pages, this.state.currentPage)

    this.currentTimeline = isAnimated(this.props.getDeviceInfo()) ? create(timeline) : null;

    if (!this.currentTimeline) return Promise.resolve();

    fadeInTimeline(this.currentTimeline, '.page')

    if (HowToPlayPages[page] === HowToPlayPages.MAIN) {
      appearence(this.currentTimeline, '.splash__box');
    }

    if (HowToPlayPages[page] === HowToPlayPages.ROUND) {
      shrink(this.currentTimeline, '.batch-cards__card');
    }

    if (HowToPlayPages[page] === HowToPlayPages.SCORE) {
      shrink(this.currentTimeline, '.how-to__score-view')
    }

    if (HowToPlayPages[page] === HowToPlayPages.CARDS) {
      fromOutside(this.currentTimeline, '.how-to__phone');
    }

    if (HowToPlayPages[page] === HowToPlayPages.RESULTS) {
      fromOutside(this.currentTimeline, '.how-to__card_result');
    }

    if (HowToPlayPages[page] === HowToPlayPages.GAME_OVER) {
      this.currentTimeline.add(histTimeline([
        {
          ref: {current: '.player-hist__item:nth-child(1)'},
          originalIndex: 2,
        },
        {
          ref: {current: '.player-hist__item:nth-child(2)'},
          originalIndex: 0,
        },
        {
          ref: {current: '.player-hist__item:nth-child(3)'},
          originalIndex: 1
        }
      ]).timeScale(2.5), 0);
    }

    return play(this.currentTimeline, 1.5);
  }

  renderComponent () {
    if (!this.props.visible) return null;

    const { locale, geo } = this.props;
    const { currentPage } = this.state;
    const pageId = get(HowToPlayPages, pages[currentPage]);

    switch (pageId) {
      case HowToPlayPages.MAIN:
        return <Main locale={locale}/>
      case HowToPlayPages.START:
        return <Start locale={locale} MOBILE_URL={MOBILE_URL_GEO[geo]} />
      case HowToPlayPages.ROUND:
        return <Round locale={locale} />
      case HowToPlayPages.CARDS:
        return <Cards locale={locale} />
      case HowToPlayPages.RESULTS:
        return <Results locale={locale} />
      case HowToPlayPages.SCORE:
        return <Score locale={locale} />
      case HowToPlayPages.GAME_OVER:
        return <GameOver locale={locale} />
      case HowToPlayPages.FAQ:
        return <Faq locale={locale} MOBILE_URL={MOBILE_URL_GEO[geo]} />
      default:
        return null;
    }
  }

  render() {
    const { useMouse, focus, locale, currentLayer, visible } = this.props;
    if (!visible) return null;
    return (
      <div className={cs("how-to-play enable-hardware-acceleration over-the-top-layer", locale)}>
        <RoundedButton
            rotate
            useMouse={useMouse}
            focus={focus}
            focusName={FOCUS_ITEM.BACK}
            handleAction={this.navigateBack}
            skin="arrow"
            css={{ activeLayer: currentLayer === this }}
        />
        <Bullets n={pages.length} m={this.state.currentPage}/>
        {this.renderComponent()}
      </div>
    )
  }
}
const Main = ({locale}) => (
    <div className={cs('page', 'main', locale)}>
      <Splash/>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.MAIN.DESCRIPTION_TOP}</div>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.MAIN.DESCRIPTION_BOTTOM}</div>
    </div>
)

const Start = ({locale, MOBILE_URL}) => (
    <div className={cs('page', locale)}>
      <div className="how-to__title">{lang.HOW_TO_PLAY.START.TITLE}</div>
      <div className="how-to__desc">{replaceMobileUrl(lang.HOW_TO_PLAY.START.DESCRIPTION_TOP, MOBILE_URL)}</div>
    </div>
)

export const CardsSimple = ({cards, supportClasses}) => (
    <div className="batch-cards">
      {
        cards.map((card) => <div
          key={card}
          className={cs("batch-cards__card", supportClasses)}
          style={{backgroundImage: `url(${card})`}}
        />)
      }
    </div>
)

const Round = ({locale}) => (
    <div className={cs('page', locale)}>
      <div className="how-to__title">{lang.HOW_TO_PLAY.ROUND.TITLE}</div>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.ROUND.DESCRIPTION_TOP}</div>
      <div className="how-to__card-img"><CardsSimple cards={DEFAULT_CARDS}/></div>
    </div>
)

const Cards = ({locale}) => (
    <div className={cs('page', locale)}>
      <div className="how-to__desc-wrap">
        <div className="how-to__desc">{lang.HOW_TO_PLAY.CARDS.DESCRIPTION_TOP}</div>
        <div className="how-to__desc">{lang.HOW_TO_PLAY.CARDS.DESCRIPTION_BOTTOM}</div>
      </div>
      <div className={"how-to__phone__" + locale}/>
    </div>
)

const Results = ({locale}) => (
    <div className={cs('page', locale)}>
      <div className="how-to__card_result"/>
      <div className="how-to__desc inline">{lang.HOW_TO_PLAY.RESULTS.DESCRIPTION_TOP}</div>
    </div>
)

const ScoreView = () => (
    <div className="how-to__score">
      <div className="how-to__score-view"       id="bubble-0">0</div>
      <div className="how-to__score-view three" id="bubble-3">+3</div>
      <div className="how-to__score-view one"   id="bubble-1">+1</div>
      <div className="how-to__score-view two"   id="bubble-2">+2</div>
    </div>
);

const Score = ({locale}) => (
    <div className={cs('page score', locale)}>
      <div className="how-to__title">{lang.HOW_TO_PLAY.SCORE.TITLE}</div>
      <ScoreView/>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.SCORE.DESCRIPTION_TOP}</div>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.SCORE.DESCRIPTION_BOTTOM}</div>
    </div>
);

const PlayerHist = () => (
    <div className="player-hist enable-hardware-acceleration">
      <div className="player-hist__item">
        <div className="player__img-wrap">
          <div className="player__img"/>
        </div>
      </div>
      <div className="player-hist__item">
        <div className="player__img-wrap">
          <div className="player__img"/>
        </div>
      </div>
      <div className="player-hist__item">
        <div className="player__img-wrap">
          <div className="player__img"/>
        </div>
      </div>
    </div>
);

const GameOver = ({locale}) => (
    <div className={cs('page game-over', locale)}>
      <div className="how-to__title">{lang.HOW_TO_PLAY.GAME_OVER.TITLE}</div>
      <div className="how-to__desc">{lang.HOW_TO_PLAY.GAME_OVER.DESCRIPTION_TOP}</div>
      <PlayerHist/>
    </div>
)

const Faq = ({locale, MOBILE_URL}) => (
    <div className={cs('page', 'faq', locale)}>
      <div className="how-to__title">{lang.HOW_TO_PLAY.FAQ.TITLE}</div>
      {lang.HOW_TO_PLAY.FAQ.DESCRIPTIONS.map((v, i) => <div className="how-to__desc list" key={i}><span>{v[0]}</span> {replaceMobileUrl(v[1], MOBILE_URL)}</div>)}
    </div>
)

export const Bullets = ({n, m}) => (
    <div className='bullets-wrapper'>
      {
        times(n, i => <div key={i} className={cs('bullet', {active: i === m})}/>)
      }
    </div>
);

const ms = ({ui}) => ({
  visible: ui.isVisibleHowToPlay
})

const mp = (dispatch) => ({
  close: () => dispatch(setVisibleHtp(false))
})

export default connect(ms, mp)(
               withIframe(
               withFocus({
                [FOCUS_ITEM.BACK]: {
                  actions: {
                    [FOCUS_ACTIONS.UP]: 'prevPage',
                    [FOCUS_ACTIONS.DOWN]: 'nextPage',
                    [FOCUS_ACTIONS.ENTER]: 'navigateBack',
                  }
                }
              })(Page)));
