import _, { split, partial, get, omit, chain } from 'lodash';
import { i18n } from '../translations';

const FIELDS_TO_OMIT = ['ref', 'playersRef', 'roundRef', 'assets'];

export const getUrlParams = (rawUrl) => chain(rawUrl)
  .replace('?', '')
  .split('&')
  .map(partial(split, _, '=', 2))
  .fromPairs()
  .value();

export class StvAppConnectorLite {
  disableKibana = false
  kibana = {}
  events = {}

  constructor(config, disableKibana) {
    this.kibana = Object.assign(getUrlParams(window.location.search), config);
    this.disableKibana = disableKibana;

    window.addEventListener('error', (err) => this.sendError('window.onerror', err), false)
    window.addEventListener('message', ({data}) => {
      if (data.method && data.method.substr(0, 8) === 'landing:') {
        const eventName = data.method.substr(8);
        this.emit(eventName, data.data);
      }
    }, false);
  }

  send = (method, payload) =>  {
    if (method !== 'state:set' && method !== 'kibana:log') {
      this.sendKibana('method:' + method, { payload });
    }

    window.parent.postMessage({
      method: 'landing:' + method,
      data: payload,
    }, '*');
  };

  emit = (name, ...args) => {
    if (this.events && this.events[name]) {
      this.events[name].forEach((cb) => cb.apply(this, args));
    }
    return this;
  };


  on = (name, cb) => {
    if (!this.events) this.events = {};
    if (!this.events[name]) this.events[name] = [];
    this.events[name].push(cb);
    return cb;
  };

  sendKibana = (type, payload = {}) => {
    if (this.disableKibana) return;
    this.send('kibana:log', {
      method: 'post',
      data: { text: type, data: JSON.stringify({...this.kibana, ...payload}) }
    });
  };

  sendError = (prefix, err, payload = {}) => {
    return this.sendKibana('GAME_ERROR', {
      name: 'Imagio',
      scope: {
        prefix,
        ...omit(payload, FIELDS_TO_OMIT)
      },
      errorContent: get(err, 'stack') || get(err, 'error.stack'),
      errorLine:    get(err, 'lineno'),
      errorCol:     get(err, 'colno'),
      errorMessage: get(err, 'message', err),
    });
  };

  setLanguage = (lang) => i18n.setLanguage(lang);

  getLanguage = () => this.kibana.lang

}
