import React from 'react';
import cs from 'classnames';

import {
  clamp,
  times,
  sumBy,
  get,
  sample,
  intersectionWith,
} from 'lodash';

import { fadeIn } from './animations/HowToPlay';
import { shrinkArray, slide } from './animations/Settings';
import { timeline, create, reverse, play, kill, remove, isAnimated } from './animations';

import {
  CardsSimple,
  Bullets
} from './HowToPlay';

import { withIframe } from './Iframe';
import {
  withFocus,
  FOCUS_ITEM,
  FOCUS_ACTIONS
} from './Focus';

import { If, withLoader } from './Utils';
import { RoundedButton } from './Buttons';

import { getAvailableDecks } from '../utils/cards';

import { i18n as lang } from '../translations';

const Section = ({children, style}) => {
  return <div className="game-settings-section enable-hardware-acceleration" style={style}>{children}</div>
}

const Subtitle = ({currentPage, picked}) => {
  const sum = sumBy(picked, "size")
  const decks = PluralSize({ size:picked.length, keyword: 'DECK' })
  const cards = PluralSize({ size:sum, keyword: 'CARD' })
  return (
    <div className={cs("subtitle enable-hardware-acceleration", {'offset-top': !!currentPage})}>
      {
        !sum
        ? lang.GAME_SETTINGS_DECKS_NOT_SELECTED
        : `${decks}, ${cards}`
      }
    </div>
  );
}

const Checkbox = ({focus, selectFn, page, picked}) => (
  <div className={cs("checkbox", {
    includes: picked.includes(page)
  })}>
    <RoundedButton
      skin="check"
      handleAction={selectFn}
      {...focus}
    />
  </div>
)

const PluralSize = ({size, keyword}) => {
  const lastDigit = Number(size.toString().slice(-1));

  let form = get(lang, `${keyword}_PLURAL_COLL`, ''); // most popular

  if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) {
    form = get(lang, `${keyword}_PLURAL_DIST`, '');
  }

  // exception
  if (size > 10 && size < 19) {
    form = get(lang, `${keyword}_PLURAL_COLL`, '');
  }

  if (size === 1 || lastDigit === 1) {
    form = get(lang, `${keyword}_SINGULAR`, '');
  }

  return `${size} ${form}`;
}

const MaybeTranslateTitle = ({locale, deck}) => (
  <div className="deck-description enable-hardware-acceleration"><p>«{get(deck, ['translations', locale], deck.title)}», <PluralSize size={deck.size} keyword="CARD"/> </p></div>
)

class SelectCardsPage extends React.Component {
  static displayName = 'SelectCards'

  state = {
    currentPage: 0,
    pages: [],
    picked: []
  }

  content = React.createRef();

  componentDidMount () {
    this.props.setActiveComponent(this);
    withLoader(getAvailableDecks()).then(({data}) => {
      this.setState({
        pages: data,
        picked: intersectionWith(data, this.props.usedDecks, (a, b) => a.id === b)
      });
      this.postAnimation()
    })
  }

  componentWillUnmount () {
    this.props.removeActiveComponent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      return this.postAnimation();
    }
  }

  nextPage = async (dir = 1) => {
    const currentPage = clamp(this.state.currentPage + dir, 0, this.state.pages.length - 1)
    if (currentPage === this.state.currentPage) return;
    try {
      await this.preAnimation()
    } catch (err) {}
    this.setState({currentPage});
  }

  prevPage = () => this.nextPage(-1);

  preAnimation = async () => remove(this.currentTimeline, "slideTmp")
    .then(() => reverse(this.currentTimeline, 2.5))
    .then(() => kill(this.currentTimeline))

  postAnimation = async () => {
    this.currentTimeline = isAnimated(this.props.getDeviceInfo()) ? create(timeline) : null;
    slide(this.currentTimeline, this.content.current, this.state.currentPage);
    shrinkArray(this.currentTimeline, times(5, () => '.current.batch-cards__card'));
    fadeIn(this.currentTimeline, '.title, .subtitle, .deck-description')
    return play(this.currentTimeline)
  }

  navigateBack = async () => {
    await this.preAnimation();
    if (this.state.picked.length === 0) return this.props.closePage([sample(this.state.pages)], false);
    return this.props.closePage(this.state.picked);
  }

  selectFn = async () => {
    if (this.state.picked.includes(this.state.pages[this.state.currentPage])) {
      this.setState((prevState) => {
        const selectedPageId = get(prevState.pages, [prevState.currentPage, 'id'])
        return {
          picked: prevState.picked.filter(({id}) => id !== selectedPageId)
        }
      })
    } else {
      this.setState((prevState) => ({
        picked: [...prevState.picked, prevState.pages[prevState.currentPage]]
      }));
    }
  }

  render () {
    const {
      locale,
      currentLayer,
      focus,
      useMouse,
    } = this.props;

    const {
      pages,
      currentPage,
      picked
    } = this.state;

    const activeLayer = currentLayer === this;
    return (
      <If a={pages.length !== 0}>
        <div className="game-settings">
          <RoundedButton
              rotate
              useMouse={useMouse}
              focus={focus}
              focusName={FOCUS_ITEM.BACK}
              handleAction={this.navigateBack}
              skin="arrow"
              css={{
                activeLayer,
                'back-button': true,
              }}
          />
          <Bullets n={pages.length} m={currentPage}/>
          <If a={currentPage === 0}>
          <div className="title">{lang.GAME_SETTINGS_DECKS}</div>
          </If>
          <Subtitle
            currentPage={currentPage}
            picked={picked}
          />
          <div className="content" ref={this.content}>
            {
              pages.map((page, i) => (
              <Section key={page.id}>
                <CardsSimple
                  cards={page.preview}
                  supportClasses={{current: i === currentPage, hidden: i !== currentPage}}
                />
                <If a={i === currentPage}>
                  <Checkbox
                    page={page}
                    selectFn={this.selectFn}
                    picked={picked}
                    focus={{
                      useMouse,
                      focus,
                      focusName: FOCUS_ITEM.SELECT,
                      css: { activeLayer }
                    }}
                    />
                </If>
                <If a={i >= currentPage}>
                  <MaybeTranslateTitle locale={locale} deck={page} lang={lang} />
                </If>
              </Section>
              ))
            }
          </div>
        </div>
      </If>
    );
  }
}

const FOCUS_SCHEME = {
  [FOCUS_ITEM.SELECT]: {
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.BACK,
    actions: {
      [FOCUS_ACTIONS.UP]: 'prevPage',
      [FOCUS_ACTIONS.DOWN]: 'nextPage',
      [FOCUS_ACTIONS.ENTER]: 'selectFn',
    }
  },
  [FOCUS_ITEM.BACK]: {
    [FOCUS_ACTIONS.RIGHT]: FOCUS_ITEM.SELECT,
    [FOCUS_ACTIONS.UP]: FOCUS_ITEM.SELECT,
    [FOCUS_ACTIONS.DOWN]: FOCUS_ITEM.SELECT,
    [FOCUS_ACTIONS.ENTER]: 'navigateBack',
  },
};

export const GameSettings = withFocus(FOCUS_SCHEME)(withIframe(SelectCardsPage));
