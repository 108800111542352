import React from 'react';
import cs from 'classnames';
import {connect} from 'react-redux';
import gsap from 'gsap'


import {
    PALETTE,
    ICONS,
    DEFAULT_ICON,
    USE_ANIMATION,
    GAME_STATUS
} from './../constants';

import {
    explainAssociation,
    explainMoveVote,
    explainMaster,
    inlineWidth
} from './../utils/players';

import {
    isStatusChanged,
    isRoundChanged,
    isPlayersChanged,
    isSomebodyLeaveGame,
    sortPlayersByQueue,
    compareFalseToTrue,
} from '../utils/game';

import { If } from './Utils';

export const Avatar = ({color, icon}) => {
    return (
        <div className='player__img-wrap'>
            <div style={{
                borderColor: color,
                backgroundColor: color,
                backgroundImage: `url('/img/avatar/${icon || DEFAULT_ICON}')`,
                backgroundSize: 41
            }} className='player__img'/>
        </div>
    );
}

export class PlayersCircles extends React.Component {
    currRef = null;

    componentDidMount () {
        gsap.fromTo(this.currRef.current, {opacity: 0}, {opacity: 1, delay: 0.3})
    }

    render() {
        const {players, limit} = this.props;
        this.currRef = React.createRef();
        return (
            <div ref={this.currRef} className={cs('players', {'more-than-limit': players.length > limit})}>{players.map((player, i) => (
            <Avatar
                key={player.id}
                player={player}
                color={player.color || PALETTE[i]}
                icon={player.icon || ICONS[i]}
                zIndex={players.length - i}
            />))}
            </div>
        )
    }
}

const Score = ({color, score, lastDiff, status}) => {
    const number = status === GAME_STATUS.SHOW_RESULTS ? `+${lastDiff || 0}` : score;
    return (
      <span
        style={{backgroundColor: status === GAME_STATUS.SHOW_SCORE ? color : ''}}
        className="player-score">
        {number}
      </span>
    )

}


const Player = ({player, game, round, i}) => {
    if (!player.color || !player.icon) return null;

    const isMaster = explainMaster     (player, round, game.status);
    const master   = explainAssociation(player, round, game.status);
    const move     = explainMoveVote   (player, round, game.status);
    const vote     = explainMoveVote   (player, round, game.status, 'votes');

    return (
        <div className={cs("player-list-item player", {
            animated: USE_ANIMATION,
            master: isMaster,
            move: (!master && move && !vote),
            vote: (!master && !move && vote),
            removed: player.removed,
        })}
             style={inlineWidth(game.status)(player.score)}>
            <Avatar
                player={player}
                color={player.color}
                icon={player.icon}
            />
            <If a={game.status !== GAME_STATUS.NEW}>
                <span className="player-id">{player.username || player.id}</span>
                <Score
                    score={player.score}
                    lastDiff={player.lastDiff}
                    status={game.status}
                    color={player.color}
                />
            </If>
        </div>
    )
};


const mapStateToProps = ({game}) => ({game, round: game.roundState});
const mapDispatchToProps = dispatch => ({});


class Sidebar extends React.Component {
    shouldComponentUpdate(nextProps) {
        const next = nextProps;
        const prev = this.props;
        return isStatusChanged(next.game.status, prev.game.status)
            || isRoundChanged(next.round, prev.round)
            || isPlayersChanged(next.game.players, prev.game.players)
            || isSomebodyLeaveGame(next.game.players, prev.game.players)
            || compareFalseToTrue(prev.animationCardsDisappearWasFinished, next.animationCardsDisappearWasFinished);

    }

    render() {
        const { game, round, animationCardsDisappearWasFinished } = this.props;
        const showResult = game.status === GAME_STATUS.SHOW_SCORE && animationCardsDisappearWasFinished;
        return (
            <div className={cs("players-sidebar", {'full': showResult, 'animated': USE_ANIMATION})}>
                {sortPlayersByQueue(game.players).map((pl, i) => <Player player={pl} key={pl.id} game={game} round={round} i={i}/>)}
            </div>
        );
    }
}

export const PlayersSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
