import React from 'react';

import backgroundImage from '../../img/oms.png';

const VIOLET = {
  to: {
    x: '-35%',
    y: '50%',
    rotation: 30
  },
  from: {
    x: '-110%',
    y: '127%',
    rotation: 30
  }
};

const BLUE = {
  to: {
    x: '216%',
    y: '75%',
    rotation: -41
  },
  from: {
    x: '216%',
    y: '101%',
    rotation: -41
  }
};

const PINK = {
  to: {
    x: '-25%',
    y: '-25%',
    rotation: 70
  },
  from: {
    x: '-150%',
    y: '-150%',
    rotation: 70
  }
};

const ORANGE = {
  to: {
    x: '100%',
    y: '-60%',
    rotation: 13
  },
  from: {
    x: '197%',
    y: '-106%',
    rotation: 13
  }
};

export const appearence = (tl) => tl && tl
  .from('.background__violet', VIOLET.from, 0)
  .from('.background__blue'  , BLUE.from  , 0)
  .from('.background__pink'  , PINK.from  , 0)
  .from('.background__orange', ORANGE.from, 0)
  .duration(2)

export const Background = () => (
  <div className="bg-wrap enable-hardware-acceleration">
    <div className="background__violet" />
    <div className="background__blue" />
    <div className="background__pink" />
    <div className="background__orange" />
  </div>
)

export const StaticBackground = () => <div className="bg-static" style={{backgroundImage: `url(${backgroundImage})`}} />
