import React from 'react';
import {connect} from 'react-redux';

import { GAME_STATUS } from '../constants';

import { i18n as lang } from '../translations';

const statuses = (key, lang) => {
  switch (key) {
    case GAME_STATUS.WAIT_MOVES:
      return lang.WAIT_MOVES;
    case GAME_STATUS.WAIT_VOTES:
      return lang.WAIT_VOTES;
    default:
      return null;
  }
};

const GameStatus = ({ status }) => {
  const tr = statuses(status, lang);
  return (tr && <div className="game-status">{tr}</div>)
};


const mapMasterToState = ({ game }) => ({ master: game.roundState.master, status: game.status, players: game.players });

export const WaitAssociation = connect(mapMasterToState)(({master, status, players = []}) => {
  if ((!master.id && status !== GAME_STATUS.WAIT_ASSOCIATION)) return null;

  return (
    <div className="board__title-wrapper">
      <div className="board__title">
        <div className="username">{master.username || master.id}</div>
        {lang.WAITING_ASSOCIATION}
      </div>
    </div>
  );
});


export default GameStatus;
