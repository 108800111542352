import React             from 'react';
import cs                from 'classnames';
import { withAnimation } from './animations/Buttons';
import { withIframe }    from './Iframe';
import { isAnimated }    from './animations';

import { ReactComponent as Arrow } from '../img/arrow_r.svg';
import { ReactComponent as Check } from '../img/check.svg';
import { ReactComponent as Exit }  from '../img/exit.svg';
import { ReactComponent as Menu }  from '../img/menu.svg';

const SKIN_MAP = {
  arrow: <Arrow/>,
  check: <Check/>,
  exit: <Exit/>,
  menu: <Menu/>,
}

class GenericButton extends React.Component {
  button = React.createRef();

  click = (...args) => {
    if (isAnimated(this.props.getDeviceInfo())) return withAnimation(this.button).then(() => this.props.handleAction(...args))
    return this.props.handleAction(...args);
  }

  render () {
    return new Error("GenericButton should be implemented fn render()");
  }
}

export class Rounded extends GenericButton {
  render () {
    const {
      focus,
      useMouse,
      rotate,
      focusName,
      skin,
      css,
    } = this.props;
    return (
      <div
        ref={this.button}
        className={cs(
          "button-rounded",
          "enable-hardware-acceleration",
          skin,
          {
            focused:    focusName === focus,
            mouseHover: useMouse,
            rotate:     !!rotate,
            ...css
          })}
        onClick={this.click}>
          {SKIN_MAP[skin]}
      </div>
    );
  }
}

export class Regular extends GenericButton {
  render () {
    const { text, css } = this.props;
    return (
      <div
        ref={this.button}
        className={cs("button enable-hardware-acceleration", css)}
        onClick={this.click}>
        {text}
      </div>
    );
  }
}

export const Button        = withIframe(Regular);
export const RoundedButton = withIframe(Rounded);
