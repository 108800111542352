import gsap from 'gsap';
import { create, play } from '../animations';

export const buttonClickTimeline = (elem = {}) => gsap.timeline({
    repeat: 0,
    paused: true,
  })
  .to(elem.current, {
    scale: 0.96,
  }, '+=0.0')
  .to(elem.current, {
    scale: 1.04,
  }, '+=0.2')
  .to(elem.current, {
    scale: 1,
  }, '+=0.2')

export const withAnimation = (ref) => play(create(buttonClickTimeline, ref), 5);
