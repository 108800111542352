import React         from 'react';
import { connect }   from 'react-redux';
import qr            from 'qrcode-generator';
import { isNaN }     from 'lodash';
import cs            from 'classnames';
import { version }   from '../../package.json'
import { withFocus } from './Focus';
import spinner       from '../img/spinner.svg';
import { store }     from '../store';
import { setVisibleLoader } from '../store/reducers/ui';

import {
  SHOW_GAME_ID,
  QR_CONFIG,
  MOBILE_APP_DOMAIN_GEO
} from '../constants'

import { compareFalseToTrue } from '../utils/game';

export const If = ({a, children}) => a ? children : null;

export const GameId = ({id, round}) => (SHOW_GAME_ID ? <div className="game-id-overlap">{id} {!isNaN(round) ? `(${round + 1})` : null}</div> : null)

export const GameVersion = () => {
  const env = /prod/.test(process.env.REACT_APP_ENV) ? '' : process.env.REACT_APP_ENV
  return <div className="game-version-overlap">{version}{env}</div>
}


export const QrLink = ({code, geo}) => {
  const generator = qr(...QR_CONFIG);
  generator.addData(`${MOBILE_APP_DOMAIN_GEO[geo]}/?code=${code}`);
  generator.make();
  return <div className="svg-qr breathing" dangerouslySetInnerHTML={{__html: generator.createSvgTag()}} />
}

export const PopupPageLayer = ({children, show, transparent, tint}) => {
  if (!show) return null;
  return <div className={cs([
    "enable-hardware-acceleration",
    "over-the-top-layer",
    "fullscreen_wrapper"
    ], {
      "solid-background": !transparent,
      tint
    })}>{children}</div>
}

class Spinner extends React.Component {
  componentDidUpdate (prevProps) {
    // emulate disabled focus
    if (compareFalseToTrue(prevProps.visible, this.props.visible)) {
      this.props.lock();
    }

    // enable focus in underlying component
    if (compareFalseToTrue(this.props.visible, prevProps.visible)) {
      this.props.release();
    }
  }

  render () {
    return (
      <PopupPageLayer show={this.props.visible} transparent tint>
        <img src={spinner} alt="spinner" className="spinner rotating"/>
      </PopupPageLayer>
    );
  }
}

const mapState = ({ui}) => ({visible: ui.isVisibleLoader})

export const Loader = withFocus({})(connect(mapState)(Spinner))

export const withLoader = async (promise) => {
  store.dispatch(setVisibleLoader(true));
  const res = await promise;
  store.dispatch(setVisibleLoader(false));
  return res;
}
