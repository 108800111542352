import React from 'react';
import cs    from 'classnames';

import {
  withFocus,
  FOCUS_ACTIONS
} from './Focus';

import { Button } from './Buttons';
import { If } from './Utils';

import { withIframe } from './Iframe'

export const isPopupShowing = ({showConfirmExit}, prev) => showConfirmExit !== prev.showConfirmExit


class PopupComponent extends React.Component {
  static displayName = 'Popup'

  componentDidMount () {
    this.props.setActiveComponent(this);
    this.props.setFocus(this.props.buttons[0]); //set initital focus
  }
  componentWillUnmount () {
    this.props.removeActiveComponent();
  }

  navigateBack = async () => this.props.dismiss();

  buttonUp = async () => {
    this.props.setFocus(this.handleKey(-1));
  }

  buttonDown = async () => {
    this.props.setFocus(this.handleKey(1));
  }

  buttonEnter = async (focus = this.props.focus) => {
    const callback = this.props.handlers[this.props.buttons.indexOf(focus)];
    if (callback) return callback()
  }

  handleClick = (mouseFocusKey) => {
    return this.buttonEnter(this.props.buttons[mouseFocusKey]);
  }

  handleKey = (direction = 1) => {
    const { focus } = this.props;
    const index = this.props.buttons.indexOf(focus) + direction
    if (index < 0 || index >= this.props.buttons.length) return this.props.buttons[index - direction];
    return this.props.buttons[index];
  }

  render () {
    const {
      useMouse,
      focus,
      currentLayer,
      title,
      locale,
      buttons
    } = this.props;
    return (
      <div className="popup-wrapper enable-hardware-acceleration">
        <div className="shadow"/>
        <div className={cs("popup-wrapper-inner", `popup-buttons-${buttons.length}`)}>
          <If a={title}><div className={cs("title", locale)}>{title}</div></If>
          {
            buttons.map((btn, key) => (
              <Button
                key={key}
                handleAction={this.handleClick.bind(this, key)}
                css={{
                  focused: focus === btn,
                  mouseHover: useMouse,
                  activeLayer: currentLayer === this,
                }}
                text={btn}
              />
            ))
          }
        </div>
      </div>
    )
  }
}

const FOCUS_SCHEME = {
  any: {
    actions: {
      [FOCUS_ACTIONS.UP]: 'buttonUp',
      [FOCUS_ACTIONS.DOWN]: 'buttonDown',
      [FOCUS_ACTIONS.ENTER]: 'buttonEnter'
    }
  }
}

export const Popup = withIframe(withFocus(FOCUS_SCHEME)(PopupComponent));
