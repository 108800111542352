import React           from 'react';
import { connect }     from 'react-redux';
import { withRouter }  from 'react-router';

import {
  filter,
  debounce
} from 'lodash';

import {
  GAME_STATUS,
  MIN_PLAYERS,
  PAUSE_DEBOUNCE
} from '../constants';

import {
  createBatchRecord
} from '../database/game';

import {
  calculateScore as calcScore,
  aggregateVotesWithCards
}  from '../utils/cards';

import {
  isRoundChanged,
  isStatusChanged,
  isRoundFinished,
  isAssociationPicked,
  isMovesFulfilled,
  isPlayersChanged,
  filterRemoved,
  isMasterLeaveGame,
  onPlayers as setPlayersSnapshot,
  gameProcessing,
  handleGameStarted,
  handleCalculateScore,
  logAsyncError,
  compareFalseToTrue,
  isHostDevice,
  setPause,
} from '../utils/game';

import {
  setGameState,
  setRoundState,
  setPlayers,
  flushGameState,
} from '../store/reducers/game/actions';

import {
  getGame,
  getCards,
  getPlayersForCurrentGame,
  getCurrentRound,
  nextGameState,
  setGameStatus,
  nextRound,
  setRoundResults,
  prepareForVoting,
} from '../store/reducers/game/dispatchers';

import { setVisibleHtp } from '../store/reducers/ui';

import { PlayersSidebar }                from './Players';
import { RoundResults, Finish }          from './Results';
import GameStatus, { WaitAssociation }   from './GameStatus';
import Onboarding                        from './Onboarding';
import Cards                             from './Cards';
import Timer, { shouldShowTimer }        from './Timer';
import { RoundedButton }                 from './Buttons';

import {
  Popup,
  isPopupShowing
} from './Popup';

import {
  withFocus,
  FOCUS_ITEM,
  FOCUS_ACTIONS,
  isFocusChanged
} from './Focus';

import {
  If,
  GameId
} from './Utils';

import { withIframe } from './Iframe'

import { i18n as lang } from '../translations';


const mapStateToProps = ({game}) => ({game});

const mapDispatchToProps = dispatch => ({
  getCurrentRound: game => dispatch(getCurrentRound(game)),
  getGame:         id => dispatch(getGame(id)),
  getCards:        list => dispatch(getCards(list)),
  setGameState:    val => dispatch(setGameState(val)),
  setRoundState:   val => dispatch(setRoundState(val)),
  setPlayers:      val => dispatch(setPlayers(val)),
  nextRound:       (game, player) => dispatch(nextRound(game, player)),
  nextGameState:   (ref, status) => dispatch(nextGameState(ref, status)),
  prepareForVoting: (game) => dispatch(prepareForVoting(game)),
  flushGameState:   () => dispatch(flushGameState()),
  setGameStatus:    (ref, status) => dispatch(setGameStatus(ref, status)),
  setRoundResults:  (voters, ref, status) => dispatch(setRoundResults(voters, ref, status)),
  getPlayersForCurrentGame: ref => dispatch(getPlayersForCurrentGame(ref)),
  openHtp: () => dispatch(setVisibleHtp(true)),
});

class Board extends React.Component {
  static displayName = 'Board'

  state = {
    showConfirmExit: false,
    animationCardsDisappearWasFinished: false,
    secondsLeft: 0,
  }

  unsubscribeFromPlayersUpdates = () => {}
  unsubscribeFromGameUpdates    = () => {}
  unsubscribeFromRoundUpdates   = () => {}

  withGame = gameProcessing(this)
  onError  = logAsyncError("Board")
  setPause = debounce((val) => {
    if (!isHostDevice(this.props)) return;
    if (this.props.game.pause.enabled === val) return;
    setPause(this.props.game.id, val, this.state.secondsLeft)
      .catch((err) => this.onError(this.props.game)(err));
  }, PAUSE_DEBOUNCE)

  componentDidMount () {
    this.props.setActiveComponent(this);
    this.props.getGame(this.props.match.params.game)
      .then(() => this.getGameData())
      .then(() => {
        this.unsubscribeFromGameUpdates    = this.props.game.ref.onSnapshot(this.onGame);
        this.unsubscribeFromPlayersUpdates = this.props.game.playersRef.onSnapshot(this.onPlayers);
        this.setPause(false);
      }).catch((err = 'Promise:no-data') => {
        this.onError(this.props.game)(err)
        this.exitGame();
      });
  }

  componentWillUnmount () {
    this.unsubscribeFromPlayersUpdates();
    this.unsubscribeFromRoundUpdates();
    this.props.removeSession();
    this.props.removeActiveComponent();
    this.props.flushGameState();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const next = nextProps.game;
    const prev = this.props.game;
    return isStatusChanged(next.status, prev.status) ||
           isRoundChanged(next.roundState, prev.roundState) ||
           isPlayersChanged(next.players, prev.players) ||
           isPopupShowing(nextState, this.state) ||
           isFocusChanged(nextProps, this.props) ||
           compareFalseToTrue(this.state.animationCardsDisappearWasFinished, nextState.animationCardsDisappearWasFinished);
  }

  componentDidUpdate (prevProps) {
    const prev = prevProps.game;
    const next = this.props.game;

    if (isPlayersChanged(next.players, prev.players) || isRoundChanged(next.roundState, prev.roundState)) {
      this.props.setRoundResults(aggregateVotesWithCards(next.roundState, next.players));
    }

    if (!isHostDevice(this.props) && next.currentRound !== prev.currentRound) {
      return this.getRound();
    }
  }

  onGame = (doc) => {
    this.props.setGameState(doc.data());
    switch (this.props.game.status) {
      case GAME_STATUS.FINISHED:
        return this.props.history.push(`/game/${this.props.match.params.game}/results`);
      case GAME_STATUS.STARTED:
        return this.withGame(handleGameStarted);
      case GAME_STATUS.CALCULATE_SCORE:
        return this.withGame(handleCalculateScore);
      case GAME_STATUS.SHOW_SCORE:
      case GAME_STATUS.SHOW_RESULTS:
        return this.buttonRight();
      default:
        return this.buttonLeft();
    }
  }

  onRound = (doc) => {
    const data = doc.data();
    if (!data) return;

    this.props.setRoundState(data);
    if (!isHostDevice(this.props)) return;

    const { status, roundState, players, ref } = this.props.game;
    const activePlayers = filter(players, filterRemoved);

    if (isRoundFinished(roundState, activePlayers)) {
      return this.props.setRoundResults(aggregateVotesWithCards(roundState, players), ref, status);
    }
    if (isMovesFulfilled(roundState, activePlayers, status)) {
      return this.props.prepareForVoting(this.props.game);
    }
    if (isAssociationPicked(roundState, status)) {
      return this.proceedNextState();
    }
  }

  onPlayers = (snapshot) => {
    setPlayersSnapshot(this)(snapshot)
    if (!isHostDevice(this.props)) return;

    const { players, ref, status, roundState } = this.props.game;

    if (status !== GAME_STATUS.NEW && filter(players, filterRemoved).length < MIN_PLAYERS) {
      this.props.removeSession();
      return this.props.setGameStatus(ref, GAME_STATUS.FINISHED);
    }

    if (status === GAME_STATUS.WAIT_ASSOCIATION && isMasterLeaveGame(players, roundState.master.id)) {
      return this.props.setGameStatus(ref, GAME_STATUS.STARTED);
    }

  }

  getGameData = () => Promise.all([
    this.props.getCards(this.props.game.usedDecks),
    this.props.getPlayersForCurrentGame(this.props.game.ref),
    this.getRound()
  ])

  getRound = () => this.props.getCurrentRound(this.props.game).then(() => {
    this.unsubscribeFromRoundUpdates();
    this.unsubscribeFromRoundUpdates = this.props.game.roundRef.onSnapshot(this.onRound);
  }).catch(() => console.log("get-round: skip until it doesn't exists"))

  nextRound = (nextPlayer) => {
    this.cardsWasHidden();
    this.unsubscribeFromRoundUpdates();
    return this.props.nextRound(this.props.game, nextPlayer).then(() => {
      this.unsubscribeFromRoundUpdates = this.props.game.roundRef.onSnapshot(this.onRound);
    }).catch((err) => this.onError(this.props.game)(err));
  }

  calculateScore = () => {
    const batchScore = createBatchRecord();
    calcScore(this.props.game, (player, score) => batchScore.update(player.ref, { score: player.score + score, lastDiff: score }));
    batchScore.update(this.props.game.roundRef, { finished: true });
    return batchScore.commit().catch((err) => this.onError(this.props.game)(err))
  }


  // for focus button (arrow on results)
  proceedNextState = () => {
    const { status, ref } = this.props.game;
    return this.props.nextGameState(ref, status);
  }

  navigateBack = async () => this.openPopup(true);

  exitGame = () => {
    this.props.removeSession();
    this.props.flushGameState();
    this.props.history.push('/');
  }

  openPopup = async (showConfirmExit = false) => {
    this.setPause(showConfirmExit);
    this.setState({showConfirmExit})
  }

  buttonLeft = async () => {
    if (this.props.currentLayer !== this) return;
    this.props.setFocus(FOCUS_ITEM.MENU)
  }

  buttonRight = async () => {
    if (this.props.currentLayer !== this || !isHostDevice(this.props)) return;
    const { status } = this.props.game;
    if (status === GAME_STATUS.SHOW_SCORE || status === GAME_STATUS.SHOW_RESULTS) {
      this.props.setFocus(FOCUS_ITEM.NEXT)
    }
  }

  buttonEnter = async () => {
    const { focus, currentLayer } = this.props;
    if (currentLayer !== this) return;
    if (focus === FOCUS_ITEM.NEXT) return this.proceedNextState();
    if (focus === FOCUS_ITEM.MENU) return this.openPopup(true)
  }

  /* utils function */

  cardsWasHidden = (flag = false) => this.setState({animationCardsDisappearWasFinished: flag})
  openHowToPlay = async () => this.props.openHtp()
  updateTimer = (secondsLeft = 0) => this.setState({secondsLeft})

  render () {
    const { status, roundState, code } = this.props.game;
    const { focus, useMouse, currentLayer } = this.props;
    const vendorInfo = this.props.getDeviceInfo();
    return (
      <div className="fullscreen">
        <div className='board'>
          <GameId id={code} round={roundState.number || 0} />
          <RoundResults>
            <PlayersSidebar animationCardsDisappearWasFinished={this.state.animationCardsDisappearWasFinished}/>
          </RoundResults>
        <If a={shouldShowTimer(status)}><Timer ignoreState={!isHostDevice(this.props)} syncFn={this.updateTimer}/></If>
          <If a={status === GAME_STATUS.WAIT_ASSOCIATION}><WaitAssociation /></If>
          <Cards cardsWasHidden={this.cardsWasHidden} vendorInfo={vendorInfo} />
          <GameStatus status={status}/>
          <Onboarding />
        </div>
        <If a={this.state.showConfirmExit}>
          <Popup
            buttons={[lang.BUTTON_CONTINUE_GAME, lang.HOW_GAME, lang.BUTTON_STOP_GAME]}
            handlers={[this.openPopup, this.openHowToPlay, this.exitGame]}
            dismiss={this.openPopup}
          />
        </If>
        <RoundedButton
          skin="menu"
          useMouse={useMouse}
          focus={focus}
          focusName={FOCUS_ITEM.MENU}
          handleAction={this.navigateBack}
          css={{
            activeLayer: currentLayer === this,
          }}
        />
        <If a={status === GAME_STATUS.SHOW_SCORE || status === GAME_STATUS.SHOW_RESULTS}>
          <If a={isHostDevice(this.props)}>
          <RoundedButton
            useMouse={useMouse}
            focus={focus}
            focusName={FOCUS_ITEM.NEXT}
            handleAction={this.proceedNextState}
            skin='arrow'
            css={{
              activeLayer: currentLayer === this,
            }}
          />
          </If>
          <If a={status === GAME_STATUS.SHOW_SCORE}><Finish/></If>
        </If>
      </div>
    );
  }
}

const focusActions = {
  [FOCUS_ACTIONS.LEFT]:  'buttonLeft',
  [FOCUS_ACTIONS.RIGHT]: 'buttonRight',
  [FOCUS_ACTIONS.ENTER]: 'buttonEnter',
}

export default withIframe(
               withFocus({
                 [FOCUS_ITEM.MENU]: {
                   actions: focusActions
                 },
                 [FOCUS_ITEM.NEXT]: {
                   actions: focusActions
                 },
               })(
               withRouter(
               connect(mapStateToProps, mapDispatchToProps)(Board))));
