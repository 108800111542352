import React from 'react';
import { connect } from 'react-redux';
import { GAME_STATUS } from './../constants';
import { i18n as lang } from '../translations';

class Onboarding extends React.Component {
  renderText = ({status, timeoutWarning}) => {
    if ((status === GAME_STATUS.WAIT_MOVES || GAME_STATUS.WAIT_VOTES) && timeoutWarning) return (<div className="onboarding-message enable-hardware-acceleration">{lang.PICK_CARD_TIMER}</div>);
    if (status === GAME_STATUS.WAIT_MOVES) return (<div className="onboarding-message enable-hardware-acceleration">{lang.PICK_CARD_ASSOC}</div>);
    return null;
  }

  render () {
    return this.renderText(this.props);
  }
}

const mapState = ({game, ui}) => ({status: game.status, timeoutWarning: ui.timeoutWarning });

export default connect(mapState)(Onboarding);
