import React from 'react';
import { connect } from 'react-redux';

import {
  SECONDS_TO_SHOW_TIP,
  // SECONDS_TO_HIDE_TIP,
  MOVE_VOTE_TIMEOUT,
  GAME_STATUS,
  USE_TIMEOUT_KICK,
} from '../constants';

import {
  showTimerWarning,
} from '../store/reducers/ui';

import {
  setupTimer,
  drawTimer,
  Canvas
} from './Canvas';


import { nextGameState, prepareForVoting } from '../store/reducers/game/dispatchers';

import { compareKeysExceeded, compareFalseToTrue } from '../utils/game';

const TICK_DELAY = 1000;

export const shouldShowTimer = (status) => USE_TIMEOUT_KICK && (
  GAME_STATUS.WAIT_ASSOCIATION === status ||
  GAME_STATUS.WAIT_MOVES       === status ||
  GAME_STATUS.WAIT_VOTES       === status
);

class Timer extends React.Component {
  skipMovesTimerId = null

  state = {
    secondsElapsed: 0,
  }

  componentDidMount () {
    if (!USE_TIMEOUT_KICK) return;
    if (this.props.game.pause.enabled) return;
    this.runTimer();
  }

  componentWillUnmount () {
    this.resetTimer();
  }

  componentDidUpdate (prevProps) {
    const nextStatus = this.props.game.status;
    const nextPause  = this.props.game.pause;
    const prevStatus = prevProps.game.status;
    const prevPause  = prevProps.game.pause;

    this.props.syncFn(this.getCurrentTime()); // sync timer


    if (nextStatus !== prevStatus && shouldShowTimer(nextStatus)) {
      this.resetTimer();
      if (!nextPause.enabled) this.runTimer();
    }

    // check if pause was enabled and pause timer
    if (compareFalseToTrue(prevPause.enabled, nextPause.enabled)) {
      return this.pauseTimer();
    }

    // check if pause was disabled and resume timer
    // (also clear any timer that can be created accidentally as async effect)
    if (compareFalseToTrue(nextPause.enabled, prevPause.enabled)) {
      return this.runTimer();
    }
  }

  runTimer = () => {
    clearTimeout(this.skipMovesTimerId);
    this.skipMovesTimerId = setTimeout(this.tickFn, TICK_DELAY);
  }

  pauseTimer = () => {
    clearTimeout(this.skipMovesTimerId);
  }

  resetTimer = () => {
    clearTimeout(this.skipMovesTimerId);
    this.setState({secondsElapsed: 0});
    this.props.showTimerWarning(false);
  }

  tickFn = () => {
    const {secondsElapsed} = this.state;
    if (secondsElapsed === SECONDS_TO_SHOW_TIP && !this.props.timeoutWarning) {
      this.props.showTimerWarning(true);
    }

    if (secondsElapsed >= MOVE_VOTE_TIMEOUT) return this.proceedNextState(this.props.game);
    this.setState(({secondsElapsed}) => ({ secondsElapsed: secondsElapsed + 1 }))
    this.runTimer()
  }

  proceedNextState = ({roundState, status}) => {
    if (this.props.ignoreState || !USE_TIMEOUT_KICK) return;
    if (status === GAME_STATUS.WAIT_ASSOCIATION && compareKeysExceeded(roundState.moves, 0)) {
      // proceed to results
      return this.props.nextGameState(this.props.game.ref, GAME_STATUS.SHOW_RESULTS);
    }

    if (status === GAME_STATUS.WAIT_MOVES) {
      return this.props.prepareForVoting(this.props.game);
    }
    this.props.nextGameState(this.props.game.ref, this.props.game.status);
  }

  getCurrentTime = () => MOVE_VOTE_TIMEOUT - this.state.secondsElapsed

  render() {
    if (!USE_TIMEOUT_KICK) return null;
    return (
      <div className="timer">
        <div className="seconds">{this.getCurrentTime()}</div>
        <Canvas
          setup={setupTimer}
          draw={drawTimer}
          fns={{
            getDuration: this.getCurrentTime,
          }}
        />
      </div>
    );
  }
}

const mapState = ({ui, game}) => ({
  timeoutWarning: ui.timeoutWarning,
  game
});

const mapActions = (dispatch) => ({
  showTimerWarning: (v) => dispatch(showTimerWarning(v)),
  nextGameState: (ref, status) => dispatch(nextGameState(ref, status)),
  prepareForVoting: (game) => dispatch(prepareForVoting(game)),
})


export default connect(mapState, mapActions)(Timer);
