import gsap from 'gsap';

export const histTimeline = (elements) => {
  const tl = gsap.timeline({repeat: 0})
  elements.forEach((elem, i) => {
    tl.from(elem.ref.current, {
      x: '-100%',
      opacity: 0,
      duration: 1,
    }, `+=${i * 0.1}`)
  });
  return tl;
}
