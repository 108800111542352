import { concat, times, shuffle } from 'lodash';
import { docId } from '../database/game';
import { SPLIT_THRESHOLD, CARDS_LIMIT, DECK_NAMES } from '../constants';
import { app as Firebase } from '../database/common';
import { getUrlParams } from './iframe';

export const getUsedDecks = (list = DECK_NAMES) => {
  return Firebase.getFirestore().collection('decks')
    .where(docId, 'in', list)
    .where('enabled', '==', true)
    .get()
    .then((decks) => {
      if (decks.size === 0) return Promise.reject(`Try to get non-existing or disabled decks: ${list}`)
      return Promise.all(decks.docs.map(d => d.ref.collection('cards').get()))
                    .then((decks) => decks.reduce((acc, deck) => concat(acc, deck.docs.map(d => d.data())), []))
                    .then((decks) => ({cards: concat([], ...decks)}));
    })
}


export const splitToRows = (arr) => {
  const divider = arr.length > SPLIT_THRESHOLD ? Math.round(arr.length / 2) : arr.length;
  return [arr.slice(0, divider), arr.slice(divider)]
}


export const lootCards = (batch) => (gameState) => {
    const {
      bazar,
      players,
      deck,
    } = gameState;

    let blockedCards = players.reduce((acc, pl) => acc.concat(...pl.currentCards), []);

    let filteredBazar = bazar;

    // fill
    if (bazar.length < players.length) {
      filteredBazar = deck.cards.map(({id}) => id).filter((card) => blockedCards.indexOf(card) === -1);
    }

    players.forEach((pl) => {
      if (pl.currentCards.length < CARDS_LIMIT) {
        const newCards = times(CARDS_LIMIT - pl.currentCards.length, () => {
          const randCardIndex = Math.floor(Math.random() * filteredBazar.length);
          return filteredBazar.splice(randCardIndex, 1)
        });
        batch.update(pl.ref, {
          currentCards: pl.currentCards.concat(...newCards)
        });
      }
    });

    batch.update(gameState.ref, { bazar: filteredBazar });

    return batch;
}

export const everyoneVoteForMaster = (master, votes) => Object.values(votes).every((card) => card === master);
export const noOneVoteForMaster    = (master, votes) => !Object.values(votes).some((card) => card === master);

export const calculateScore = (game, addScore) => {
  const { roundState, players } = game;
  const { master, votes, moves } = roundState;

  if (everyoneVoteForMaster(moves[master.id], votes)) {
    players.forEach((player) => {
      if (player.id !== master.id) return addScore(player, 2);
      return addScore(player, 0);
    });
  } else {
    const bonusForNoOne = noOneVoteForMaster(moves[master.id], votes) ? 2 : 0;

    players.forEach((player) => {
      // skip master if nobody vote for him
      if (bonusForNoOne && player.id === master.id) return addScore(player, 0);
      // find own move
      const ownMove = moves[player.id];
      const ownVote = votes[player.id];

      let score = 0;
      // master branch
      if (player.id === master.id) {
        score = 3;
      } else {
        if (ownVote === moves[master.id]) score += 3; // score for card of master
        score += Object.values(votes).filter((vote) => vote === ownMove).length; // score for wrong moves by other players
        score += bonusForNoOne; // bonus
      }

      return addScore(player, score);
    });
  }
}


export const getCardById = (id, deck) => deck.cards.find(card => id === card.id) || {skin:''};

export const skinStyle = (skin, additionalStyles = {}) => ({ backgroundImage: `url("${skin}")`, ...additionalStyles });

export const aggregateVotesWithCards = ({ moves, votes }, players) => Object.keys(moves).reduce((acc, movePlayer) => {
  acc[moves[movePlayer] /* card id */] = Object.keys(votes).reduce((acc, votePlayer) => {
    if (votes[votePlayer] === moves[movePlayer]) {
      const p = players.find(({id}) => id === votePlayer);
      if (p) return acc.concat([p]);
    }
    return acc;
  }, []);
  return acc;
}, {});

export const shuffleMoves = (moves = {}) => shuffle(Object.keys(moves));

export const getDefaultDeckName = (n = 0) => DECK_NAMES[n % DECK_NAMES.length];

export const getDeckByName = (name = getDefaultDeckName()) => DECK_NAMES.find(n => n === name);

export const getDeckFromQ = () => {
  const {deck} = getUrlParams(window.location.search);
  if (!deck) return getDefaultDeckName()
  return deck;
}

export const getAvailableDecks = (data = {}) => Firebase.callFn('getAvailableDecks', data);
