import { Dispatch } from 'redux';

import { batch } from 'react-redux';
import { isEmpty } from 'lodash';


import { nextStatus, logAsyncError } from '../../../utils/game';
import { getUsedDecks, lootCards, shuffleMoves } from '../../../utils/cards';

import {
  fetchGame,
  getPlayers,
  getRounds,
  createBatchRecord,
  createTransaction,
  union,
  ts,
} from '../../../database/game';

import { app as Firebase } from '../../../database/common';

import {
  initialState,
  roundState,
  setGameRef,
  setGameState,
  setGameCards,
  setPlayersRef,
  setPlayers,
  setRoundRef,
  setRoundState,
  setVoters,
} from './actions';

import {
  GAME_STATUS,
  LOG_TYPES
} from '../../../constants';

import { log } from '../../../components/Iframe';

export const createGame = (did: string) => (dispatch: Dispatch) => {
  return Firebase.callFn('createNewGame', {did}).then(({data}) => {
    dispatch(setGameState(data));
    return data.id;
  })
}

export const getCards = (list = initialState.usedDecks) => (dispatch: Dispatch) => {
  return getUsedDecks(list)
    .then((cards) => dispatch(setGameCards(cards)))
    .catch((err: any) => {
        logAsyncError('getCards')({list})(err)
        getUsedDecks()
          .then((cards) => dispatch(setGameCards(cards)))
          .catch((err: any) => {
            logAsyncError('getDefaultCards')({list})(err)
          });
    })
}

export const getCurrentRound = (game: GameState) => (dispatch: Dispatch) => {
  if (game.currentRound !== null) {
    return getRounds(game.ref!)
      .then((roundsRef: any) => roundsRef.doc(game.currentRound!).get())
      .then((round: firebase.firestore.DocumentSnapshot) => batch(() => {
        dispatch(setRoundRef(round.ref));
        dispatch(setRoundState(round.data() as Round));
      }))
      .catch((err: any) => logAsyncError('getCurrentRound')(game)(err));
  }
  return Promise.reject()
}

export const getGame = (id: GameId) => (dispatch: Dispatch) => {
  return fetchGame(id).then(({ ref, data }) => {
    if (!data.exists) return Promise.reject(`No game with id = ${id}`);
    return batch(() => {
      dispatch(setGameRef(ref));
      dispatch(setGameState(data.data() as GameState));
    });
  }).catch((err: any) => {
    return Promise.reject(err);
  });
}

export const getPlayersForCurrentGame = (ref: GameRef) => (dispatch: Dispatch) => {
  return getPlayers(ref).then(({ refs, data }) => {
    const players = data.docs.map((pl) => ({...pl.data(), ref: pl.ref}));
    return batch(() => {
      dispatch(setPlayersRef(refs));
      dispatch(setPlayers(players as Player[]));
    });
  }).catch((err: any) => {
    logAsyncError('getPlayersForCurrentGame')({id: ref.id})(err)
    return Promise.reject(err)
  });
}

export const setGameStatus = (ref: GameRef, status: GameStatus) => (dispatch: Dispatch) => {
  if (status === GAME_STATUS.FINISHED) {
    log(LOG_TYPES.GAME_END, { gameId: ref.id });
  }
  return createTransaction(async (tr: firebase.firestore.Transaction) => tr.update(ref, { status, statusUpdated: ts() }));
}

export const nextGameState = (ref: GameRef, currState: GameStatus) => setGameStatus(ref, nextStatus(currState));

/* todo rewrite and simplify */
export const nextRound = (game: GameState, nextUser: Player) => (dispatch: Dispatch) => {
  return getRounds(game.ref!).then((roundsRef) => {
    return roundsRef.get().then((pastRounds) => {
      const newRound   = roundsRef.doc();
      const newRoundData = Object.assign(roundState, {
        master: {
          id: nextUser.ref!.id,
          username: nextUser.username
        },
        number: pastRounds.size
      });
      return lootCards(createBatchRecord())(game)
              .set(newRound, newRoundData)
              .update(game.ref, {
                status: nextStatus(game.status),
                statusUpdated: ts(),
                currentRound: newRound.id
              })
              .commit()
              .then(batch(() => {
                dispatch(  setRoundRef(newRound));
                dispatch(setRoundState(newRoundData));
              }))
              .catch((err: any) => logAsyncError('nextRound:lootCards:commit')({ game, nextUser, newRound, newRoundData })(err))
    }).catch((err: any) => logAsyncError('nextRound:roundsRef:get')({ game, roundsRef })(err));
  }).catch((err: any) => logAsyncError('nextRound:getRounds')({ game })(err));
}


export const setRoundResults = (voters: RoundResults, ref: GameRef, currState: GameStatus) => (dispatch: Dispatch) => {
  if (isEmpty(voters)) return;
  return batch(() => {
    dispatch(setVoters(voters))
    if (ref) dispatch(nextGameState(ref, currState) as any)
  })
}

export const prepareForVoting = (game: GameState) => (dispatch: Dispatch) => {
  if (!game.roundRef || !game.ref) return;
  return createBatchRecord()
    .update(game.ref, { status: nextStatus(game.status), statusUpdated: ts() })
    .update(game.roundRef, {
      movesShuffled: union(...shuffleMoves(game.roundState.moves))
    })
    .commit().catch((err: any) => logAsyncError('prepareForVoting:commit')({ game })(err));
}
