export const DEBUG            = true;
export const USE_TIMEOUT_KICK = true;
export const USE_ANIMATION    = process.env.REACT_APP_USE_ANIMATION === 'true';
export const USE_CANVAS       = true;
export const USE_SOUND        = true;
export const SHOW_GAME_ID     = true;
export const USE_SPLASH       = process.env.NODE_ENV !== 'development';
export const PARTICLES_SIZE   = 22;

export const CARDS_LIMIT = 5;

export const MAX_PLAYERS = 10;
export const MIN_PLAYERS = 3;
export const SCORE_LIMIT = 30;
export const MOVE_VOTE_TIMEOUT = 59;
export const TIME_WARNING = 15;
export const TIP_DURATION = 5;
export const SECONDS_TO_SHOW_TIP = MOVE_VOTE_TIMEOUT - TIME_WARNING;
export const SECONDS_TO_HIDE_TIP = SECONDS_TO_SHOW_TIP + TIP_DURATION;
export const PLAYER_CLICK_DEBOUNCE = 1000;
export const SKIP_ROUND_TIMEOUT = 1000;
export const TIMER_FRAMERATE = 10;
export const PAUSE_DEBOUNCE = 1000;
export const SPLASH_DELAY = 3000;

export const GAME_STATUS = {
  NEW:               'new',
  STARTED:           'started',
  WAIT_ASSOCIATION:  'wait_association',
  WAIT_MOVES:        'wait_moves',
  WAIT_VOTES:        'wait_votes',
  CALCULATE_SCORE:   'calculate_score',
  SHOW_RESULTS:      'show_results',
  SHOW_SCORE:        'show_score',
  FINISHED:          'finished',
};

// create new round
// wait master's card
// wait other's cards
// wait votes for cards
// calculate score
// show score
// show res
export const GAME_STATUS_LOOP = ((states: Array<string>) => states.slice(1, states.length - 1))(Object.values(GAME_STATUS));

export const PALETTE = [
'#fe0000',
'#fea128',
'#fae70d',
'#ade601',
'#00e2fb',
'#288dff',
'#b728fe',
'#fd28dc',
'#888888',
'#000000',
]

export const ICONS = [
'ganco.png',
'leao.png',
'peixe.png',
'vaca.png',
'girafa.png',
'macaco.png',
'rato.png',
'elephant.png',
'pato.png',
'tigre.png',
]
export const DEFAULT_ICON = ICONS[0];

export const SPLIT_THRESHOLD = MAX_PLAYERS / 2;

export const DECK_NAMES = [
  'fancy-ext'
];

export const VIDEO_FILLER = '//s1.vcdn.biz/static/93645881/black.mp4';

export const ROTATIONS = {
  0: {transform: 'rotate(2deg)'},
  1: {transform: 'rotate(-2deg)'},
  2: {transform: 'rotate(3deg)'},
  3: {transform: 'rotate(-1deg)'},
  4: {transform: 'rotate(1deg)'},
  5: {transform: 'rotate(-3deg)'},
  6: {transform: 'rotate(2deg)'},
  7: {transform: 'rotate(-1deg)'},
  8: {transform: 'rotate(3deg)'},
  9: {transform: 'rotate(-3deg)'},
  10: {transform: 'rotate(1deg)'},
}

export const CARDS_PREVIEW = [
  {
    x: '-80%',
    rotate: -7
  },
  {
    x: '-40%',
    y: -10,
    rotate: -3
  },
  { y: -17 },
  {
    x: '40%',
    y: -10,
    rotate: 4
  },
  {
    x: '80%',
    rotate: 8
  }
]

export const SLIDES_CONTENT_OFFSET = 450;

export const QR_CONFIG = [0, 'M']

export const RESPONSE_ERRORS = {
  NO_FREE_CODE: 'NO_FREE_CODE',
};

export const MOBILE_URL_GEO = {
  default: "megogo.net/imagio",
  ru: "megogo.ru/imagio"
};

export const MOBILE_APP_DOMAIN_GEO = {
  default: "https://imagio-mob-game.megogo.net",
  ru:      "https://imagio-mob-game.megogo.ru"
}

export const BLACK_LIST_VENDORS = (process.env.REACT_APP_NO_ANIMATION_VENDOR || "").split(',')

export const LOG_TYPES = {
  GAME_START: "GAME_START",
  GAME_END:   "GAME_END",
}
