import React          from 'react';
import { connect }    from 'react-redux';
import { withRouter } from 'react-router';
import cs             from 'classnames';

import { rearg, isEqual } from 'lodash';

import {
  USE_CANVAS,
  USE_ANIMATION
} from '../constants';

import { Button, RoundedButton }  from './Buttons';
import { If }                     from './Utils';

import { Avatar }                 from './Players';

import {
  Popup,
  isPopupShowing
} from './Popup';

import { withFocus, FOCUS_ITEM, FOCUS_ACTIONS, isFocusChanged } from './Focus';

import { Canvas, setupResults, drawResults } from './Canvas';

import { getPlayersForCurrentGame, getGame } from '../store/reducers/game/dispatchers';
import { flushGameState }                    from '../store/reducers/game/actions';
import { setVisibleHtp }                     from '../store/reducers/ui';

import { logAsyncError, proceedToNextGame, isHostDevice }  from '../utils/game';

import { isAnimated, create, reverse, play } from './animations';
import { histTimeline } from './animations/Histogram';
import { withIframe }   from './Iframe'
import { i18n as lang } from '../translations';


const sortScore = (arr) => arr.sort((a, b) => b.score - a.score);
const applyOrder = rearg((...args) => [...args], [2, 0, 1]);
const assignRef = (arr) => arr.map((item, i) => {
  item.ref = React.createRef();
  item.originalIndex = i;
  return item;
});

const PlayerHist = React.forwardRef(({username, score, color, icon}, currRef) => (
    <div className="player-hist__item" ref={currRef}>
        <div className="player-hist__username">{username}</div>
        <div className="player-hist__score"> {score}</div>
        <Avatar color={color} icon={icon}/>
    </div>
))

const isPlayersReceived = (prev, next) => (prev.topPlayers.length !== next.topPlayers.length)

class Results extends React.Component {
  static displayName = 'Results'

  onError = logAsyncError('Results')
  unsubscribeGame = () => {}

  state = {
    showConfirmExit: false,
    topPlayers:[],
  }

  componentDidMount () {
    this.props.setActiveComponent(this);
    this.props.getGame(this.props.match.params.game)
    .then(() => this.props.getPlayers(this.props.game.ref))
    .then(this.filterByHighscore)
    .then(() => {
      // sub if not host
      if (isHostDevice(this.props) || !this.props.game.ref) return;
      this.unsubscribeGame = this.props.game.ref.onSnapshot(this.onNextGame)
    })
    .catch((err) => this.onError({...this.props.game, ...this.state})(err))
  }

  componentWillUnmount () {
    this.unsubscribeGame();
    this.props.removeActiveComponent();
    this.props.flushGameState();
  }

  shouldComponentUpdate (nextProps, nextState) {
    return isPlayersReceived(this.state, nextState) || isFocusChanged(this.props, nextProps) || isPopupShowing(nextState, this.state);
  }

  filterByHighscore = () => {
    this.setState({
      topPlayers: applyOrder(...assignRef(sortScore(this.props.game.players).slice(0, 3)))
    }, () => {
      this.timeline = isAnimated(this.props.getDeviceInfo()) ? create(histTimeline, this.state.topPlayers) : null;
      play(this.timeline);
    });
  }

  onNextGame = (doc) => {
    const { nextGame } = doc.data();
    if (!isEqual(nextGame, this.props.game.nextGame)) {
      reverse(this.timeline, 2.5)
        .then(() => this.props.history.push(`/lobby/${nextGame}`));
    }
  }

  proceedNewGame = () => {
    if (!isHostDevice(this.props)) return Promise.resolve('proceedNewGame: not host')
    return Promise.all([
      proceedToNextGame(this.props.game.ref, this.props.did),
      reverse(this.timeline, 2.5)
    ]).then(([id]) => Promise.resolve(this.props.history.push(`/lobby/${id}`)))
      .catch((err) => this.onError({})(err));
  }
  navigateBack = async () => {
    this.openPopup(true);
  }

  exitGame = () => {
    this.props.removeSession();
    this.props.flushGameState();
    this.props.history.push('/');
  }

  openPopup = async (showConfirmExit = false) => this.setState({showConfirmExit})

  openHowToPlay = async () => {
    this.props.openHtp()
  }

  render () {
    const { topPlayers } = this.state;
    const { focus, useMouse, currentLayer } = this.props;
    if (!topPlayers.length) return null;
    return (
      <div className="results">
          <div className="player-hist enable-hardware-acceleration">
              { topPlayers.map((pl,i, array) => <PlayerHist className={cs({'top': i[1]})} key={pl.id} {...pl} e={array} />) }
          </div>
       <RoundedButton
          skin="menu"
          useMouse={useMouse}
          focus={focus}
          focusName={FOCUS_ITEM.MENU}
          handleAction={this.navigateBack}
          css={{
            activeLayer: currentLayer === this,
          }}
        />
        <Button text={lang.NEW_GAME} css={{
          disabled: !isHostDevice(this.props),
          focused: focus === FOCUS_ITEM.NEW_GAME,
          mouseHover: useMouse,
          activeLayer: currentLayer === this
        }} handleAction={this.proceedNewGame}/>
        <If a={USE_ANIMATION && USE_CANVAS}>
          <Canvas setup = {setupResults} draw={drawResults}/>
        </If>
        <If a={this.state.showConfirmExit}>
          <Popup
            buttons={[lang.BUTTON_CONTINUE_GAME, lang.HOW_GAME, lang.BUTTON_STOP_GAME]}
            handlers={[this.openPopup, this.openHowToPlay, this.exitGame]}
            dismiss={this.openPopup}
          />
        </If>
      </div>
    );
  }
}

const FOCUS_SCHEME = {
  [FOCUS_ITEM.NEW_GAME]: {
    [FOCUS_ACTIONS.UP]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.ENTER]: 'proceedNewGame'
  },
  [FOCUS_ITEM.MENU]: {
    [FOCUS_ACTIONS.DOWN]: FOCUS_ITEM.NEW_GAME,
    [FOCUS_ACTIONS.RIGHT]: FOCUS_ITEM.NEW_GAME,
    [FOCUS_ACTIONS.ENTER]: 'navigateBack'
  }
}

const mapState = ({ game }) => ({ game });
const mapProps = dispatch => ({
  getGame:        id  => dispatch(getGame(id)),
  getPlayers:     ref => dispatch(getPlayersForCurrentGame(ref)),
  flushGameState: ()  => dispatch(flushGameState()),
  openHtp:        ()  => dispatch(setVisibleHtp(true)),
});

export default withIframe(
               withFocus(FOCUS_SCHEME)(
               withRouter(
               connect(mapState, mapProps)(Results))));

export const RoundResults = ({
  showScore,
  showResults,
  children,
  handleAction,
  navigateBack,
  useMouse,
  focus
})  => (
  <div className={cs("board__sidebar enable-hardware-acceleration", {
    'show-score': showScore,
    'show-results': showResults
  })}>
    {children}
  </div>
);

export const Finish = () => <div className='finish_img'/>;
