import React        from 'react';
import hoistStatics from "hoist-non-react-statics";

import {
  last,
  get,
  dropRight,
  first,
  keys,
  omit
} from 'lodash';

import { applyKeycodes } from '../config';
import { logAsyncError } from '../utils/game';

import { withIframe } from './Iframe'

import { withAnimation } from './animations/Buttons';
import { isAnimated }    from './animations';

export const FOCUS_ITEM = {
  NEW_GAME:    'new_game',
  HOW_TO_PLAY: 'how_to_play',
  START_GAME:  'start_game',
  NEXT:        'next',
  DISMISS:     'dismiss',
  OK:          'ok',
  BACK:        'back',
  MENU:        'menu',
  SELECT:      'select',
};

export const FOCUS_ACTIONS = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  ENTER: 'ENTER',
  NAV_BACK: 'RETURN'
}
let KEYCODES = {};

const FocusContext = React.createContext();

FocusContext.displayName = "New focus";


class Focus extends React.Component {
  locked = false
  onError = logAsyncError("Focus")
  state = {
    focus: null,
    focusStack: [],
    activeComponentStack: [],
    useMouse: false,
  }

  componentDidMount () {
    KEYCODES = applyKeycodes(this.props.getKeycodes())
    attachListeners(this.onKeys, this.onMouse);
  }

  componentWillUnmount () {
    detachListeners(this.onKeys, this.onMouse);
  }

  setActive = (activeComponent) => {
    const initialFocus = first(keys(get(activeComponent, ['props', 'focusScheme'])))
    if (!initialFocus) return;
    this.setState(({focusStack, activeComponentStack}) => {
      return {
        focus:                initialFocus,
        focusStack:           [...focusStack, initialFocus],
        activeComponentStack: [...activeComponentStack, activeComponent],
        useMouse:             false,
      }
    })
  }

  removeActive = () => {
    this.setState(({focusStack, activeComponentStack}) => ({
      focus:                last(dropRight(focusStack)),
      focusStack:           dropRight(focusStack),
      activeComponentStack: dropRight(activeComponentStack),
    }))
    this.releaseFocus();
  }

  setFocus = (focus, action) => {
    if (focus) return this.setState(({focusStack}) => ({
      focus,
      focusStack: [...dropRight(focusStack), focus]
    }))
    return this.applyActionInActiveComponent(action);
  }

  onKeys = (event) => {
    if (this.locked) return;

    const keyCode = KEYCODES[event.keyCode];
    // ad hoc for BACK button
    if (this.state.useMouse && (keyCode === "RETURN" || keyCode === "HID_BACK")) {
        this.applyBackAction();
        return;
    }

    // restore default focus
    if (this.state.useMouse) {
      const activeComponent = last(this.state.activeComponentStack);
      this.removeActive();
      this.setActive(activeComponent);
      return;
    }

    switch (keyCode) {
      case "UP":
      case "DOWN":
      case "LEFT":
      case "RIGHT":
        this.setFocusInActiveComponent(keyCode);
        break;
      case "ENTER":
        this.applyAnimatedClick(keyCode);
        break;
      case "HID_BACK":
      case "RETURN":
        this.applyBackAction();
        break;
      case "GREEN":
        window.location.reload();
        break;
      default:
        break;
    }
  }

  onMouse = () => {
    this.setState({
      useMouse: true,
      focus: null,
    });
  }

  setFocusInActiveComponent = (direction) => {
    const activeComponent = last(this.state.activeComponentStack);
    this.setFocus(get(activeComponent, [
      'props',
      'focusScheme',
      this.state.focus,
      direction
    ], null), direction)
  }

  applyActionInActiveComponent = (action) => {
    const activeComponent = last(this.state.activeComponentStack);
    const newAction       = get(activeComponent, ['props', 'focusScheme', this.state.focus, action], null) ||
                            get(activeComponent, ['props', 'focusScheme', this.state.focus, 'actions', action], null)||
                            get(activeComponent, ['props', 'focusScheme', 'any', 'actions', action], null)

    if (newAction && !this.locked) {
      this.lockFocus();
      activeComponent[newAction].call(activeComponent).then(() => this.releaseFocus()).catch((err) => {
        this.onError(normalizeErrorMessage(this.state))(err);
        this.releaseFocus();
      });
    }
  }

  applyBackAction = () => {
    const activeComponent = last(this.state.activeComponentStack);
    const backAction      = get(activeComponent, ['navigateBack'], null);
    if (backAction) return backAction();
    this.navigateBack()
  }


  navigateBack = () => window.history.back();

  lockFocus = (val = true) => {
    this.locked = val;
  }

  releaseFocus = () => {
    this.lockFocus(false)
  }

  applyAnimatedClick = (keyCode) => {
    if (isAnimated(this.props.getDeviceInfo())) return withAnimation({current: '.focused'}).then(() => this.applyActionInActiveComponent(keyCode));
    this.applyActionInActiveComponent(keyCode);
  }

  render () {
    return <FocusContext.Provider children={this.props.children || null} value={{
      setActiveComponent:    this.setActive,
      removeActiveComponent: this.removeActive,
      setFocus:              this.setFocus,
      focus:                 this.state.focus,
      useMouse:              this.state.useMouse,
      currentLayer:          last(this.state.activeComponentStack),
      lock:                  this.lockFocus,
      release:               this.releaseFocus
    }}/>
  }
}

export default withIframe(Focus)

export const withFocus = (FOCUS_SCHEME = {}) => (Component) => {
  const displayName = `withFocus(${Component.displayName || Component.name})`;
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;
    return (
      <FocusContext.Consumer>
      {
        context => (<Component
        {...remainingProps}
        {...context}
        focusScheme={FOCUS_SCHEME}
        ref={wrappedComponentRef}
        />)
      }
      </FocusContext.Consumer>
    );
  };
  C.displayName = displayName;
  C.WrappedComponent = Component;
  return hoistStatics(C, Component);
}


export const isFocusChanged = (prev, next) => (prev.useMouse !== next.useMouse) || (prev.focus !== next.focus)


const attachListeners = (handler, mouse) => {
  document.addEventListener('keyup', handler, true);
  document.addEventListener('mousemove', mouse, true);
}

const detachListeners = (handler, mouse) => {
  document.removeEventListener('mousemove', mouse);
  document.removeEventListener('keyup', handler);
}

const normalizeErrorMessage = (state) => {
  const l = last(state.activeComponentStack)
  const s = omit(state, ['focusStack', 'activeComponentStack'])
  return Object.assign({}, s, { page: l && l.constructor.displayName });
};
