import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Menu      from './components/Menu';
import Lobby     from './components/Lobby';
import Board     from './components/Board';
import Results   from './components/Results';
import ScreenSv  from './components/Screensaver';
import './App.scss';

export const App = () => (
  <div className="fullscreen_wrapper">
    <Router>
        <Switch>
          <Route path="/" exact component={Menu} />
          <Route path="/lobby/:game" exact component={Lobby} />
          <Route path="/game/:game" exact component={Board} />
          <Route path="/game/:game/results" exact component={Results} />
          <Route component={Menu} />
        </Switch>
    </Router>
    <ScreenSv />
  </div>
);

export default App;
