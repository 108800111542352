import React        from 'react';

import { VIDEO_FILLER } from '../constants'

import { applyScreensaver } from '../config';
import { logAsyncError, compareFalseToTrue } from '../utils/game';
import { withIframe } from './Iframe';

class Screensaver extends React.Component {
  videoRef = React.createRef()

  state = {
    enabled: false,
  }

  componentDidMount () {
    this.setState({
      enabled: applyScreensaver(this.props.getDeviceInfo())
    })
  }
  componentDidUpdate (_, prevState) {
    if (compareFalseToTrue(prevState.enabled, this.state.enabled)) {
      try {
        this.videoRef && this.videoRef.current && this.videoRef.current.play()
      } catch (e) {
        logAsyncError('VideoScreensaver')({})(e)
      }
    }
  }
  render () {
    if (!this.state.enabled) return null;
    return (<video src={VIDEO_FILLER} loop autoPlay muted className="filler" ref={this.videoRef} />)
  }
}

export default withIframe(Screensaver)
