import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import gsap from 'gsap';
import cs from 'classnames';

import {
  GAME_STATUS,
  MIN_PLAYERS,
  USE_ANIMATION,
  MOBILE_URL_GEO,
  LOG_TYPES,
} from './../constants';

import {
  getGame,
  getPlayersForCurrentGame,
  nextGameState
} from '../store/reducers/game/dispatchers';


import {
  setPlayers,
  flushGameState
} from '../store/reducers/game/actions';

import { setVisibleHtp } from '../store/reducers/ui';

import {
  startGame,
  onPlayers,
  logAsyncError,
  isHostDevice
} from './../utils/game';

import { PlayersSidebar }    from './Players'
import { Popup } from './Popup';

import {
  Button,
  RoundedButton
} from './Buttons';

import {
  withFocus,
  FOCUS_ITEM,
  FOCUS_ACTIONS
} from './Focus';

import {
  QrLink,
  If,
  PopupPageLayer,
} from './Utils';

import { GameSettings } from './GameSettings';

import {
  withIframe,
  log,
} from './Iframe'

import { i18n as lang } from '../translations';


const mapStateToProps = ({game}) => ({game});

const mapDispatchToProps = dispatch => ({
  setPlayers: val => dispatch(setPlayers(val)),
  getGame: (id) => dispatch(getGame(id)),
  getPlayersForCurrentGame: (id) => dispatch(getPlayersForCurrentGame(id)),
  nextGameState: (ref, status) => dispatch(nextGameState(ref, status)),
  flushGameState: () => dispatch(flushGameState()),
  openHtp: () => dispatch(setVisibleHtp(true)),
});

class Lobby extends React.Component {
  static displayName = 'Lobby'

  unsubscribePlayers = () => {}
  unsubscribeGame    = () => {}

  onError            = logAsyncError('Lobby')
  lobbyPage          = React.createRef()

  state = {
    usedDecks: [],
    showConfirmExit: false,
    showConnectTimeout: false,
    showSettingsPage: false,
  }

  componentDidMount () {
    this.props.setActiveComponent(this);
    this.props.getGame(this.props.match.params.game)
      .then(() => this.props.getPlayersForCurrentGame(this.props.game.ref))
      .then(() => {
        switch (this.props.game.status) {
          case GAME_STATUS.NEW:
            this.onNewGame();
            break;
          case GAME_STATUS.FINISHED:
            this.props.history.push(`/game/${this.props.game.id}/results`);
            return;
          default:
            this.props.history.push(`/game/${this.props.game.id}`);
            return; // prevent to subscribe players
        }
        gsap.to(this.lobbyPage.current, { opacity: 1 });
        this.unsubscribePlayers = this.props.game.playersRef.onSnapshot(onPlayers(this));
      })
      .catch((err) =>{
        this.onError(this.props.game)(err);
        this.props.removeSession();
        this.props.history.push('/');
      })
  }

  componentWillUnmount () {
    // unsubscribe firebase
    this.unsubscribePlayers();
    this.unsubscribeGame();
    this.props.flushGameState();
    this.props.removeActiveComponent();
    clearTimeout(this.eolTimer);
  }

  procceedToGameBoard = () => {
    if (!this.canStartGame()) {
      if (this.props.game.did !== this.props.did) return Promise.reject('This device is not a host');
      return Promise.reject('Not enough players');
    }

    clearTimeout(this.eolTimer);

    return this.getUsedDecks()
      .then(decks => startGame(this.props.game, decks))
      .then(() => log(LOG_TYPES.GAME_START, { gameId: this.props.game.id }))
      .then(() => Promise.resolve(this.props.history.push(`/game/${this.props.game.id}`)))
      .catch((err) => this.onError(this.props.game)(err));
  }

  /* utils */

  navigateBack = async () => {
    this.openPopup(true);
  }

  exitGame = () => {
    clearTimeout(this.eolTimer);
    this.props.removeSession();
    this.props.flushGameState();
    this.props.history.push('/');
  }

  canStartGame = () => (this.props.game.players.length >= MIN_PLAYERS && this.props.game.did === this.props.did)

  openPopup = async (
    showConfirmExit = false,
    showConnectTimeout = false,
    showSettingsPage = false
  ) => this.setState({
    showConfirmExit,
    showConnectTimeout,
    showSettingsPage
  })

  startCountdown = (eol) => {
    const tickFn = () => {
      if (eol - Date.now() <= 0) {
        this.openPopup(false, true)
        return clearTimeout(this.eolTimer);
      }
      this.startCountdown(eol);
    }
    this.eolTimer = setTimeout(tickFn, 1000);
  }

  waitUntilStart = (gameRef) => {
    if (this.props.game.did === this.props.did) return;
    this.unsubscribeGame = gameRef.onSnapshot((doc) => {
      const { status } = doc.data()
      switch (status) {
        case GAME_STATUS.NEW:
          return;
        case GAME_STATUS.FINISHED:
          this.props.history.push(`/game/${this.props.game.id}/results`);
          return;
        default:
          this.props.history.push(`/game/${this.props.game.id}`);
          return; // prevent to subscribe players
      }
    });
  }

  onNewGame = () => {
    if (this.props.game.endOfLife) this.startCountdown(this.props.game.endOfLife.toDate());
    if (this.props.game.did && this.props.did) this.waitUntilStart(this.props.game.ref);
  }

  closeTimeoutPopup = async () => {
    this.openPopup();
    this.onError(this.props.game)({message: "GAME_CONNECT_TIMEOUT"});
    this.props.removeSession();
    this.props.history.push('/');
  }

  openHowToPlay = async () => {
    this.props.openHtp()
  }

  openGameSettings = async () => {
    if (!isHostDevice(this.props)) return;
    this.openPopup(false, false, true);
  }

  storeDecks = (picked, store = true) => {
    this.openPopup(false, false, false);
    const usedDecks = picked.map(({id}) => id);
    this.props.saveUsedDecks(store ? usedDecks : []);
    this.setState({ usedDecks })
  }

  getUsedDecks = async () => {
    const usedDecks = this.props.getUsedDecks();
    if (usedDecks && usedDecks.length) return usedDecks;
    if (this.state.usedDecks.length) return this.state.usedDecks;
    return this.props.game.usedDecks;
  }

  render() {
    const canStartGame = !this.canStartGame();
    const { focus, useMouse, currentLayer, geo } = this.props;
    const isHost = isHostDevice(this.props)
    const usedDecks = this.props.getUsedDecks();

    return (
      <div ref={this.lobbyPage} className="lobby-page">
          <div className="game-info">
            <div className="text-info">
              <div className="game-id">{this.props.game.code}</div>
              <div className="game-descr">{lang.LOBBY_DESCRIPTION} <span>{MOBILE_URL_GEO[geo]}</span></div>
            </div>
            <QrLink code = {this.props.game.code} geo={geo}/>
          </div>
          <div className={cs("start-page__buttons", { animated: USE_ANIMATION })}>
            <Button
              text={lang.START_GAME}
              css={{
                disabled: canStartGame,
                focused: focus === FOCUS_ITEM.START_GAME,
                mouseHover: useMouse,
                activeLayer: currentLayer === this,
              }}
              handleAction={this.procceedToGameBoard}
            />
            <Button
              text={lang.SELECT_DECK}
              css={{
                disabled: !isHost,
                focused: focus === FOCUS_ITEM.SELECT,
                mouseHover: useMouse,
                activeLayer: currentLayer === this,
              }}
              handleAction={this.openGameSettings}
            />
          </div>
          <If a={this.state.showConfirmExit}>
            <Popup
              buttons={[lang.BUTTON_CONTINUE_GAME, lang.HOW_GAME, lang.BUTTON_STOP_GAME]}
              handlers={[this.openPopup, this.openHowToPlay, this.exitGame]}
              dismiss={this.openPopup}
            />
          </If>
          <PopupPageLayer show={this.state.showSettingsPage}>
            <GameSettings
              closePage={this.storeDecks}
              usedDecks={usedDecks}
            />
          </PopupPageLayer>
          <If a={this.state.showConnectTimeout}>
            <Popup
              title={lang.GAME_CONNECT_TIMEOUT}
              buttons={[lang.BUTTON_CONTINUE_GAME]}
              handlers={[this.closeTimeoutPopup]}
              dismiss={this.closeTimeoutPopup}
            />
          </If>
          <RoundedButton
            skin="menu"
            useMouse={useMouse}
            focus={focus}
            focusName={FOCUS_ITEM.MENU}
            handleAction={this.navigateBack}
            css={{
              activeLayer: currentLayer === this,
            }}
          />
          <PlayersSidebar/>
      </div>
    );
  }
}

const FOCUS_SCHEME = {
  [FOCUS_ITEM.START_GAME]: {
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.UP]:   FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.DOWN]: FOCUS_ITEM.SELECT,
    [FOCUS_ACTIONS.ENTER]: 'procceedToGameBoard',
  },
  [FOCUS_ITEM.SELECT]: {
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.UP]:   FOCUS_ITEM.START_GAME,
    [FOCUS_ACTIONS.ENTER]: 'openGameSettings',
  },
  [FOCUS_ITEM.MENU]: {
    [FOCUS_ACTIONS.RIGHT]: FOCUS_ITEM.START_GAME,
    [FOCUS_ACTIONS.DOWN]:  FOCUS_ITEM.START_GAME,
    [FOCUS_ACTIONS.ENTER]: 'navigateBack'
  },
}

export default withIframe(
               withFocus(FOCUS_SCHEME)(
               withRouter(connect(
                 mapStateToProps,
                 mapDispatchToProps
               )(Lobby))));
