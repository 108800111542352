import React from 'react';
import * as p5 from 'p5/lib/p5.min';

import {
  arc,
  triangle,
  circle
} from '../utils/game';

import { ParticleSystem } from './processing/ParticleSystem';

import {
  MOVE_VOTE_TIMEOUT,
  TIME_WARNING,
  TIMER_FRAMERATE
} from '../constants';

export class Canvas extends React.Component {
  ref = React.createRef()

  componentDidMount () {
    const fns = this.props.fns || {};
    new p5((p) => {
      p.setup = this.props.setup && this.props.setup.bind(p);
      p.draw  = this.props.draw  && this.props.draw.bind(p);
      // bind helpers to processing context
      Object.keys(fns).forEach((key) => {
        p[key] = fns[key].bind(p)
      });
    }, this.ref.current)
  }

  render () {
    return <div ref={this.ref} className="canvas-overlay"/>
  }
}


export const setupResults = function () {
  this.gameState = {};

  this.createCanvas(640, 360);

  this.gameState.particleSystems = [
      new ParticleSystem(this, arc(this))
    , new ParticleSystem(this, triangle(this))
    , new ParticleSystem(this, circle(this))
  ];
}

export const drawResults = function () {
  this.clear();
  this.gameState.particleSystems.forEach(ps => ps.run());

  if (this.gameState.particleSystems.map(ps => ps.empty()).every(ps => ps)) this.noLoop();
}


export const setupTimer = function () {
  this.frameRate(TIMER_FRAMERATE);
  const cnv = this.createCanvas(200, 200);
  cnv.class('small');
}

export const drawTimer = function () {
  this.clear();
  const duration = this.getDuration();
  if (duration > 0) {
    this.deg = (180 / MOVE_VOTE_TIMEOUT) * duration;
    this.noFill();
    this.strokeWeight(20);
    this.stroke("#dfc00c");
    if (duration <= TIME_WARNING) this.stroke("#df0f0e");
    this.arc(100, 0, 150, 150, 0, this.radians(this.deg));
  }
}
