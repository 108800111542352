import { getDeckFromQ } from '../../../utils/cards';
import { getAssets } from '../../../utils/game';
import { MOVE_VOTE_TIMEOUT } from '../../../constants';

const FLUSH_GAME_STATE = 'FLUSH_GAME_STATE';
const GAME_STATE       = 'GAME_STATE';
const GAME_REF         = 'GAME_REF';
const ROUND_REF        = 'ROUND_REF';
const ROUND_STATE      = 'ROUND_STATE';
const SET_GAME_CARDS   = 'SET_GAME_CARDS';
const SET_PLAYERS      = 'SET_PLAYERS';
const SET_PLAYERS_REF  = 'SET_PLAYERS_REF';
const GAME_VOTERS      = 'SET_ROUND_RESULT';

const DECK_NAME = getDeckFromQ();
const ASSETS    = getAssets();

export const roundState: Round = {
  master: { id: '', username: ''},
  votes: {},
  moves: {},
}

const deck: Deck = {
  cards: []
}

export const initialState: GameState = {
  ref: null,
  id: null,
  code: '',
  bazar: [],
  players: [],
  playersRef: null,
  usedDecks: [DECK_NAME],
  status: 'new',
  currentRound: null,
  roundRef: null,
  roundState,
  deck,
  roundResults: null,
  assets: ASSETS,
  timeoutSeconds: MOVE_VOTE_TIMEOUT,
  pause: {
    enabled: false,
  }
};


export const gameReducer = (state: GameState = initialState, action: GameAction) : GameState => {
  switch (action.type) {
    case GAME_STATE:
    case GAME_REF:
    case ROUND_REF:
    case ROUND_STATE:
    case SET_GAME_CARDS:
    case SET_PLAYERS:
    case SET_PLAYERS_REF:
    case GAME_VOTERS:
      return Object.assign({}, state, action.value);
    case FLUSH_GAME_STATE:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export const setGameState = (value: AtLeastOne<GameState>): GameAction => ({
  type: GAME_STATE,
  value
});

export const setVoters = (roundResults: RoundResults): GameAction => ({
  type: GAME_VOTERS,
  value: { roundResults }
});

export const setGameRef = (ref: firebase.firestore.DocumentReference): GameAction => ({
  type: GAME_REF,
  value: { ref }
});

export const setRoundRef = (roundRef: firebase.firestore.DocumentReference): GameAction => ({
  type: ROUND_REF,
  value: {
    roundRef,
    currentRound: roundRef.id,
  }
});

export const setRoundState = (roundState: Round): GameAction => ({
  type: ROUND_STATE,
  value: { roundState }
});

export const setGameCards = (deck: Deck): GameAction => ({
  type: SET_GAME_CARDS,
  value: { deck }
});

export const setPlayers = (players: Player[]): GameAction => ({
  type: SET_PLAYERS,
  value: { players }
});

export const setPlayersRef = (playersRef: firebase.firestore.CollectionReference) => ({
  type: SET_PLAYERS_REF,
  value: { playersRef }
});

export const flushGameState = () => ({
  type: FLUSH_GAME_STATE,
  value: null
});
