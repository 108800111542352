import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

import {
  app as Firebase,
  COLLECTION_GAMES,
  COLLECTION_PLAYERS,
  COLLECTION_ROUNDS,
} from './common';

export const docId = firebase.firestore.FieldPath.documentId();

export const get = async (id: string) => {
  return Firebase.getFirestore().collection(COLLECTION_GAMES).doc(id)
}

export const union = (...data: Any) => {
  if (!data && !data.length) return [];
  return firebase.firestore.FieldValue.arrayUnion(...data);
}

export const ts = () => firebase.firestore.FieldValue.serverTimestamp();

export const createBatchRecord = () => Firebase.getFirestore().batch();

export const createTransaction = (updFn: any) => Firebase.getFirestore().runTransaction(updFn)

export const fetchGame = async (id: string): Promise<GameBundle> => {
  const ref  = await get(id);
  const data = await ref.get();
  return {
    ref,
    data,
  };
}

export const getPlayers = async (ref:firebase.firestore.DocumentReference): Promise<PlayersCollectionBundle> => {
  const refs = ref.collection(COLLECTION_PLAYERS);
  const data   = await refs.get();
  return {
    refs,
    data
  };
}

export const getPlayerById = (ref:firebase.firestore.DocumentReference, id: string) => {
  return ref.collection(COLLECTION_PLAYERS).doc(id)
};

export const getRounds = async (ref:firebase.firestore.DocumentReference) => {
  return ref.collection(COLLECTION_ROUNDS)
};
