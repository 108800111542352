import { GAME_STATUS, SCORE_LIMIT } from '../constants';

export const sortByQueue = (p1, p2) => (p1.queueOrder > p2.queueOrder) ? 1 : -1

export const explainAssociation = (player, round, gameStatus) => {
  return GAME_STATUS.WAIT_ASSOCIATION === gameStatus && player.id === round.master.id
}

export const explainMoveVote = (player, round, status, key = 'moves') => {
  const playerFound = round && round[key][player.id];
  if (playerFound) {
    if (GAME_STATUS.WAIT_MOVES === status && key === 'moves') return true;
    if (!explainMaster(player, round) && GAME_STATUS.WAIT_VOTES === status && key === 'votes') return true;
  }
  return false;
}

export const explainMaster = (player, round, status) => round && player.id === round.master.id && status !== GAME_STATUS.SHOW_SCORE && status !== GAME_STATUS.SHOW_RESULTS;

export const explainFinished = gameStatus => gameStatus === GAME_STATUS.FINISHED;

const limit  = (n, min = 0, max = 100) => n > max ? max : n < min ? min : n;

const MIN_WIDTH = 17;

export const calcWidth = score => limit(MIN_WIDTH + (100 - MIN_WIDTH) * limit(score / SCORE_LIMIT)) + '%';

export const inlineWidth = status => score => status === GAME_STATUS.SHOW_SCORE && score ? { width: calcWidth(score) } : {};
