import gsap from 'gsap';
import { get } from 'lodash';


import { USE_ANIMATION, BLACK_LIST_VENDORS } from '../../constants';

const DISABLE_ANIMATION = !USE_ANIMATION;

export const create = (timelineFn = () => {}, ...args) => {
  if (DISABLE_ANIMATION || !timelineFn) return null;
  return timelineFn(...args);
}

export const play = async (timeline, timeScale = 1) => {
  if (DISABLE_ANIMATION || !timeline) return;
  await timeline.timeScale(timeScale).play();
}

export const reverse = async (timeline, timeScale = 1) => {
  if (DISABLE_ANIMATION || !timeline) return;
  await timeline.timeScale(timeScale).reverse();
}

export const pause = async (timeline) => {
  if (DISABLE_ANIMATION || !timeline) return;
  await timeline.pause();
}

export const progress = (timeline) => {
  if (DISABLE_ANIMATION || !timeline) return false;
  return timeline.progress();
}

export const clear = (timeline) => {
  if (!timeline) return;
  return timeline.clear();
}

export const add = (timeline, fn = () => {}, ...args) => {
  if (DISABLE_ANIMATION || !timeline) return false;
  return fn(timeline, ...args);
}

export const join = (tl1, tl2, position = 0) => tl1.add(tl2, position);

export const timeline = (opt = {}) => gsap.timeline(Object.assign({repeat: 0, paused:true}, {}))

export const kill = async (tl) => tl && tl.kill()

export const clearInline = async (target) => {
  Array.prototype.forEach.call(document.querySelectorAll(target), (element) => {
    element.removeAttribute('style');
  });
}

export const remove = async (tl, removeId) => {
  if (DISABLE_ANIMATION || !tl) return null;
  tl.remove(tl.getById(removeId), 0);
  return tl;
}

export const isAnimated = (deviceInfo) => !BLACK_LIST_VENDORS.includes(get(deviceInfo, 'vendor', null));
