import { times } from 'lodash';

import {
  getRandomColor
} from '../../utils/game';
import { PARTICLES_SIZE } from '../../constants';

class Particle {
  constructor(proc, loc, renderType) {
    this.p = proc;
    this.rotation = 0;
    this.location = loc;
    this.velocity = this.p.createVector(this.p.random(-1,1), this.p.random(-2,0));
    this.acceleration = this.p.createVector(this.p.random(-0.1, 0.1), 0.05);
    this.lifespan = 255;
    this.particle = renderType;
    this.rotationSpeed = this.p.random(-0.3, 0.3);
    this.color = getRandomColor()
    this.scale = this.p.random(0.5, 1.5);
  }

  update() {
    this.velocity.add(this.acceleration);
    this.location.add(this.velocity);
    this.rotation += this.rotationSpeed;
    this.lifespan -= 1;
    return this;
  }

  draw () {
    this.particle(this.location, this.rotation, this.scale, this.color)
  }

  isDead() {
    return this.lifespan <= 0;
  }
}

export class ParticleSystem {

  constructor (proc, rendFn, size = PARTICLES_SIZE) {
    this.p = proc;
    this.particles = times(size, () => new Particle(proc, this.initCoords(), rendFn));
  }

  initCoords () {
    return this.p.createVector(
      this.p.width  * this.p.random(0.2, 0.8),
      this.p.height * this.p.random(-0.5, 0),
    );
  }

  upd() {
    this.particles = this.particles.filter(p => !p.isDead()).map(p => p.update());
  }

  draw() {
    this.particles.forEach(p => p.draw());
  }

  run () {
    this.upd();
    this.draw();
  }

  empty () {
    return this.particles.length === 0;
  }
}

