import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import actionCreators from './reducers';

const enhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });
const middleware = applyMiddleware(thunk);

export const store = createStore(actionCreators, undefined, enhancers(middleware));
