const UI_SHOW_TIP   = 'UI_SHOW_TIP';
const UI_SHOW_HTP   = 'UI_SHOW_HTP';
const UI_SHOW_LOADER   = 'UI_SHOW_LOADER';

const initialState = {
  timeoutWarning: false,
  isVisibleHowToPlay: false,
  isVisibleLoader: false,
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_SHOW_TIP:
    case UI_SHOW_HTP:
    case UI_SHOW_LOADER:
      return Object.assign({}, state, action.value);
    default:
      return state;
  }
};

const showTimerWarningAction = (val = true) => ({
  type: UI_SHOW_TIP,
  value: {
    timeoutWarning: val
  }
});

const setVisibleHowToPlay = (val = false) => ({
  type: UI_SHOW_HTP,
  value: {
    isVisibleHowToPlay: val
  }
});

export const setVisibleLoader = (val = false) => ({
  type: UI_SHOW_LOADER,
  value: {
    isVisibleLoader: val
  }
});

export const showTimerWarning = val => dispatch => dispatch(showTimerWarningAction(val));
export const setVisibleHtp    = val => dispatch => dispatch(setVisibleHowToPlay(val));

