import LocalizedStrings from 'react-localization';

export const GAME_NAME            = 'Imagio';
// default ru
const START_GAME           = 'Начать игру';
const NEW_GAME             = 'Новая игра';
const HOW_GAME             = 'Как играть';
const WAITING_ASSOCIATION  = ' ходит в телефоне';
const WAIT_MOVES           = 'Ходим в телефонах';
const WAIT_VOTES           = 'Голосуем в телефонах';
const PICK_CARD_ASSOC      = 'Выберите карту, наиболее подходящую загаданному слову';
const PICK_CARD_TIMER      = 'Время на исходе, выбирайте карту или пропустите ход!';
const CONFIRM_EXIT_GAME    = 'Вы действительно хотите покинуть игру?';
const DISMISS              = 'Отменa';
const OK                   = 'Да';
const BUTTON_CONTINUE_GAME = 'Вернуться'
const BUTTON_STOP_GAME     = 'Завершить игру'
const BUTTON_LEAVE_GAME    = 'Выйти'
const LOBBY_DESCRIPTION    = 'Чтобы войти в игру, отсканируйте QR-код или введите 4-значный код игры в браузере смартфона по адресу';
const NO_FREE_CODE_ERROR   = "Извините, игра не может быть создана. Попробуйте позже.";
const GAME_CONNECT_TIMEOUT = "К сожалению, отведенные на старт игры 15 минут истекли. Создайте новую игру.";

const SELECT_DECK = "Выбор карт";
const GAME_SETTINGS_DECKS = "Колоды карт";
const GAME_SETTINGS_DECKS_NOT_SELECTED = "Колодa не выбрана — будет выбрана случайная";

const CARD_SINGULAR = 'карта';
const CARD_PLURAL_DIST = 'карты';
const CARD_PLURAL_COLL = 'карт';

const DECK_SINGULAR = 'колодa';
const DECK_PLURAL_DIST = 'колоды';
const DECK_PLURAL_COLL = 'колод';

const HOW_TO_PLAY = {
  MAIN: {
    TITLE: GAME_NAME,
    DESCRIPTION_TOP: 'Перед вами карточная игра в ассоциации для компании от 3 до 10 человек. Все, что вам нужно для начала — собраться перед телевизором и взять в руки свои смартфоны :)',
    DESCRIPTION_BOTTOM: 'В каждом раунде ведущий игрок загадывает карту с ассоциацией, а задача остальных игроков — сбросить по одной карте и затем определить из них карту ведущего игрока. По результатам раундов игроки зарабатывают очки. Выигрывает тот, кто первым наберет 30 очков.',
  },
  START: {
    TITLE: 'Начало игры',
    DESCRIPTION_TOP: 'После старта игры на экране вашего телевизора появятся 4-значный код игры и QR-код. Для подключения к игре отсканируйте QR-код с телефона или откройте в мобильном браузере <%=MOBILE_URL%> и введите код там. По мере подключения участников на экране телевизора будут появляться аватарки игроков. Как только все участники подключатся — нажмите кнопку «Начать игру».'
  },
  ROUND: {
    TITLE: 'Раунд',
    DESCRIPTION_TOP: 'В начале раунда ведущий игрок озвучивает ассоциацию для одной из своих карт и сбрасывает ее. Ассоциацией может быть слово, фраза, цитата, звук — все что угодно.Увидеть, кто ведущий игрок в данный момент, вы можете с помощью подсказки на экране телевизора.',
  },
  CARDS: {
    DESCRIPTION_TOP: 'Каждый игрок на своем мобильном телефоне должен выбрать наиболее подходящую под ассоциацию ведущего игрока карту и тоже сбросить ее.',
    DESCRIPTION_BOTTOM: 'После того, как все игроки выбрали карты по ассоциации, на экране телевизора и у каждого в мобильном телефоне отобразятся все эти карты.  Теперь задача каждого — узнать карту ведущего игрока и выбрать ее на своем мобильном телефоне.',
  },
  RESULTS: {
    DESCRIPTION_TOP: 'Как только все сделали выбор, результаты отобразятся на экране телевизора. Карта ведущего игрока будет подсвечена желтой рамкой, возле нее будет видно аватарки игроков, которые верно угадали карту ведущего игрока. Также аватарками будет отмечен выбор карт остальных игроков.'
  },
  SCORE: {
    TITLE: 'Подсчет очков раунда',
    DESCRIPTION_TOP: 'Ведущий игрок получает 3 очка, если его карта была угадана, но не всеми игроками сразу. Ведущий игрок получает 0 очков, если его карту никто не угадал или угадали все сразу.',
    DESCRIPTION_BOTTOM: 'Каждый игрок, отгадавший карту ведущего игрока, получает по 3 очка. Если карту ведущего игрока отгадали все или не отгадал никто — все игроки получают по 2 очка.Если карту игрока кто-то принял за карту ведущего, он дополнительно получает по 1 баллу за каждый «ошибочный» голос.'
  },
  GAME_OVER: {
    TITLE: 'Длительность игры',
    DESCRIPTION_TOP: 'Игра продолжается до тех пор, пока кто-то из игроков не наберет 30 очков.'
  },
  FAQ: {
    TITLE: 'ЧаВо',
    DESCRIPTIONS: [
      ['У меня на телефоне что-то не так (не вижу карт, не могу проголосовать и т.д.) — что делать? ', 'Потяните экран вниз для обновления страницы.'],
      ['Я случайно закрыл браузер на телефоне, как вернуться в игру?', 'Откройте в браузере <%=MOBILE_URL%> и введите код игры повторно.'],
      ['Я подключился в чужую игру, как мне выйти из игры? ', 'Нажмите кнопку в левом верхнем углу экрана и подтвердите желание выйти.']
    ]
  }
};

// dictionary

const ru = {
  START_GAME,
  GAME_NAME,
  NEW_GAME,
  HOW_GAME,
  WAITING_ASSOCIATION,
  WAIT_MOVES,
  WAIT_VOTES,
  PICK_CARD_ASSOC,
  PICK_CARD_TIMER,
  CONFIRM_EXIT_GAME,
  DISMISS,
  OK,
  HOW_TO_PLAY,
  BUTTON_CONTINUE_GAME,
  BUTTON_STOP_GAME,
  BUTTON_LEAVE_GAME,
  LOBBY_DESCRIPTION,
  NO_FREE_CODE_ERROR,
  GAME_CONNECT_TIMEOUT,
  GAME_SETTINGS_DECKS,
  GAME_SETTINGS_DECKS_NOT_SELECTED,
  SELECT_DECK,
  CARD_SINGULAR,
  CARD_PLURAL_DIST,
  CARD_PLURAL_COLL,
  DECK_SINGULAR,
  DECK_PLURAL_DIST,
  DECK_PLURAL_COLL,
}

const ua = {
  START_GAME: 'Почати гру',
  GAME_NAME: 'Imagio',
  NEW_GAME: 'Нова гра',
  HOW_GAME: 'Як грати',
  WAITING_ASSOCIATION: ' ходить у телефоні',
  WAIT_MOVES: 'Ходимо в телефонах',
  WAIT_VOTES: 'Голосуємо в телефонах',
  PICK_CARD_ASSOC: 'Виберіть карту, що найбільше підходить до слова, яке загадали',
  PICK_CARD_TIMER: 'Час закінчується, вибирайте карту чи пропустите хід!',
  CONFIRM_EXIT_GAME: 'Ви дійсно бажаєте завершити гру?',
  DISMISS: 'Скасувати',
  OK: 'Так',
  BUTTON_CONTINUE_GAME: 'Повернутися',
  BUTTON_STOP_GAME: 'Завершити гру',
  BUTTON_LEAVE_GAME: 'Вийти',
  LOBBY_DESCRIPTION: 'Для входу в гру відскануйте QR-код або введіть 4-значний код гри в браузері смартфона за адресою',
  NO_FREE_CODE_ERROR: 'Вибачте, гра не може бути створена. Спробуйте пізніше.',
  GAME_CONNECT_TIMEOUT: 'На жаль, відведені на старт гри 15 хвилин сплили. Створіть нову гру.',
  HOW_TO_PLAY: {
    MAIN: {
      TITLE: GAME_NAME,
      DESCRIPTION_TOP: 'Перед вами — карткова гра в асоціації для компанії від 3 до 10 осіб. Усе, що вам потрібно для початку — зібратись перед телевізором і взяти в руки свої смартфони :)',
      DESCRIPTION_BOTTOM: 'У кожному раунді оповідач загадує карту з асоціацією, а завдання інших гравців — скинути по одній карті й потім визначити з них карту оповідача. За результатами раундів гравці заробляють очки. Виграє той, хто першим набере 30 очок.',
    },
    START: {
      TITLE: 'Початок гри',
      DESCRIPTION_TOP: 'Після старту гри на екрані телевізора з’являться 4-значний код гри та QR-код. Для підключення до гри відскануйте QR-код з телефону або відкрийте в мобільному браузері <%=MOBILE_URL%> і введіть код там. У міру того як учасники будуть підключатися, на екрані телевізора з’являтимуться аватарки гравців. Щойно всі учасники підключаться — натисніть кнопку «Почати гру».'
    },
    ROUND: {
      TITLE: 'Раунд',
      DESCRIPTION_TOP: 'На початку раунду оповідач озвучує асоціацію для однієї зі своїх карт і скидає її. Асоціацією може бути слово, фраза, цитата, звук — усе що завгодно. Побачити, хто наразі оповідач, ви можете за допомогою підказки на екрані телевізора.',
    },
    CARDS: {
      DESCRIPTION_TOP: 'Кожен гравець на своєму мобільному телефоні повинен вибрати найбільш відповідну до асоціації оповідача карту й також скинути її.',
      DESCRIPTION_BOTTOM: 'Після того, як усі гравці вибрали карти за асоціацією, на екрані телевізора і в кожного в мобільному телефоні відобразяться всі ці карти. Тепер завдання кожного — дізнатися карту оповідача та вибрати її на своєму мобільному телефоні.',
    },
    RESULTS: {
      DESCRIPTION_TOP: 'Коли всі зроблять свій вибір, результати відобразяться на екрані телевізора. Карта оповідача буде підсвічена жовтою рамкою, біля неї буде видно аватарки гравців, які вірно вгадали карту оповідача. Також аватарками буде відзначений вибір карт інших гравців.'
    },
    SCORE: {
      TITLE: 'Підрахунок очок раунду',
      DESCRIPTION_TOP: 'Оповідач отримує 3 очки, якщо його карту вгадали, але не всі гравці відразу. Оповідач отримує 0 очок, якщо його карту ніхто не вгадав або вгадали всі відразу.',
      DESCRIPTION_BOTTOM: 'Кожен гравець, що відгадав карту оповідача, отримує по 3 очки. Якщо карту оповідача відгадали всі, або НЕ відгадав ніхто — усі гравці отримують по 2 очки. Якщо карту гравця хтось прийняв за карту оповідача, він додатково отримує по 1 балу за кожний «помилковий» голос.'
    },
    GAME_OVER: {
      TITLE: 'Тривалість гри',
      DESCRIPTION_TOP: 'Гра продовжується доти, доки хтось із гравців не набере 30 очок.'
    },
    FAQ: {
      TITLE: 'ЧаПи',
      DESCRIPTIONS: [
        ['У мене на телефоні щось не так (не бачу карт, не можу проголосувати тощо) — що робити? ', 'Потягніть екран вниз для оновлення сторінки.'],
        ['Я випадково закрив браузер на телефоні, як повернутися у гру?', 'Відкрийте в браузері <%=MOBILE_URL%> і введіть код гри повторно.'],
        ['Я підключився в чужу гру, як мені вийти з гри? ', 'Натисніть кнопку в лівому верхньому кутку екрана й підтвердьте бажання вийти.']
      ]
    }
  },
  SELECT_DECK: "Вибір карт",
  GAME_SETTINGS_DECKS: "Колоди карт",
  GAME_SETTINGS_DECKS_NOT_SELECTED:"Колоду не вибрано — буде вибрано випадкову",
  CARD_SINGULAR: 'карта',
  CARD_PLURAL_DIST: 'карти',
  CARD_PLURAL_COLL: 'карт',
  DECK_SINGULAR: 'колодa',
  DECK_PLURAL_DIST: 'колоди',
  DECK_PLURAL_COLL: 'колод',
}

const en = {
  START_GAME: 'Start Game',
  GAME_NAME: 'Imagio',
  NEW_GAME: 'New Game',
  HOW_GAME: 'How to Play',
  WAITING_ASSOCIATION: ' is chosing a card',
  WAIT_MOVES: 'Making a move on the phones',
  WAIT_VOTES: 'Voting on the phones',
  PICK_CARD_ASSOC: 'Choose the card that best matches the clue',
  PICK_CARD_TIMER: 'Time is running out, choose a card or you\'ll skip your move!',
  CONFIRM_EXIT_GAME: 'Are you sure you want to quit the game?',
  DISMISS: 'Cancel',
  OK: 'Yes',
  BUTTON_CONTINUE_GAME: 'Resume',
  BUTTON_STOP_GAME: 'Quit',
  BUTTON_LEAVE_GAME: 'Exit',
  LOBBY_DESCRIPTION: 'To enter the game, scan the QR code or enter the 4-digit game code in the smartphone browser',
  NO_FREE_CODE_ERROR: 'Sorry, the game could not be created. Please try again later.',
  GAME_CONNECT_TIMEOUT: 'Unfortunately, your 15 minutes to start the game have expired. Please create a new game.',
  HOW_TO_PLAY: {
    MAIN: {
      TITLE: GAME_NAME,
      DESCRIPTION_TOP: 'This is an association card game for a company of 3 to 10 people. All you need to do first is get together in front of the TV and take your smartphones :)',
      DESCRIPTION_BOTTOM: 'In each round, the storyteller chooses a card and association to it, and the task of the other players is to discard one card and then guess the storyteller\'s card from them. According to the results of the rounds, players earn points. The winner is the one who first scores 30 points.',
    },
    START: {
      TITLE: 'Game Start',
      DESCRIPTION_TOP: 'After the game start, 4-digit game code and a QR code will appear on your TV screen. To connect to the game, scan the QR code from your phone or open <%=MOBILE_URL%> via mobile browser and enter the code. As players connect, player pictures will appear on your TV screen. As soon as all participants are connected, click "Start Game" button.',
    },
    ROUND: {
      TITLE: 'Round',
      DESCRIPTION_TOP: 'At the beginning of the round, the storyteller voices the association for one of his cards and discards it. An association can be a word, phrase, quote, sound — anything. To see who is the storyteller at the moment - read a hint on the TV screen.',
    },
    CARDS: {
      DESCRIPTION_TOP: 'Each player on their mobile phone must choose the card most suitable for association from the storyteller and also discard it.',
      DESCRIPTION_BOTTOM: 'Once all players have selected the cards by association, all these cards will appear on the TV screen and in your smartphone.  Now everyone\'s task is to guess the storyteller\'s card and choose it on smartphones.',
    },
    RESULTS: {
      DESCRIPTION_TOP: 'Once everyone has made their choice, results will be displayed on the TV screen. The storyteller\'s card will be highlighted with a yellow frame, next to it you\'ll see the pictures of players who have correctly guessed the storyteller\'s card. The choice of other players\' cards will also be marked with players\' pictures.'
    },
    SCORE: {
      TITLE: 'Point Counting for 1 Round',
      DESCRIPTION_TOP: 'The storyteller gets 3 points if their card was guessed, but not by all players at once. The storyteller gets 0 points if no one guessed his card or it was guessed by all at once.',
      DESCRIPTION_BOTTOM: 'Each player who guesses the storyteller\'s card receives 3 points. If the storyteller\'s card was guessed by everyone or no one, all players get 2 points. If a player\'s card is mistaken as the storyteller\'s card, he/she additionally receives 1 point for each "mistaken" vote.'
    },
    GAME_OVER: {
      TITLE: 'Game Duration',
      DESCRIPTION_TOP: 'The game continues until one of the players scores 30 points.'
    },
    FAQ: {
      TITLE: 'FAQ',
      DESCRIPTIONS: [
        ['Something is wrong on my phone (I can’t see the cards, I can’t vote, etc.) - what should I do? ',' Swipe down to reload the page.'],
        ['I have accidentally closed the browser on my phone, how can I return to the game?', 'Open <%=MOBILE_URL%> in your browser and re-enter the game code.'],
        ['I connected to someone else\'s game, how do I quit the game? ',' Press the button in the upper left corner of the screen and confirm the exit.']
      ]
    }
  },
  SELECT_DECK: "Card selection",
  GAME_SETTINGS_DECKS: "Card decks",
  GAME_SETTINGS_DECKS_NOT_SELECTED: "No deck is selected — random one will be selected",
  CARD_SINGULAR: 'card',
  CARD_PLURAL_DIST: 'cards',
  CARD_PLURAL_COLL: 'cards',
  DECK_SINGULAR: 'deck',
  DECK_PLURAL_DIST: 'decks',
  DECK_PLURAL_COLL: 'decks',
}

export const i18n = new LocalizedStrings({
  en, ru, ua
});
