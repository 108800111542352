import { CARDS_PREVIEW, SLIDES_CONTENT_OFFSET, USE_ANIMATION } from '../../constants';

const calcOffset = currentPage => ({y: -1 * SLIDES_CONTENT_OFFSET * currentPage})

export const slide = (tl, target, offset) => {
  if (!tl || !USE_ANIMATION) {
    // adhoc for disabled animation
    target.style.transform = `translate(0, ${calcOffset(offset).y}px)`
    return tl
  };
  tl.to(target, {
    id: "slideTmp",
    ...calcOffset(offset),
    duration: 0.5,
    delay: 0
  }, 0);
  return tl
}

export const shrinkArray = (tl, targets, props=CARDS_PREVIEW) => {
  if (!tl) return tl;
  targets.forEach((target, n) => tl.fromTo(`${target}:nth-child(${n + 1})`, {x: 0}, props[n], 0))
  return tl
}
