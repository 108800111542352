import { invert, get }         from 'lodash';
import { KEYCODES as GENERAL } from './general'

const WEBOS_RE = /web(o|0)s/gi;

const getUa = () => window.navigator.userAgent.toString().toLowerCase();

export const applyKeycodes = (keycodes) => {
  if (keycodes) return invert(keycodes)
  console.log('Using browser keycodes');
  return invert(GENERAL);
}


export const applyScreensaver = (deviceInfo) => {
  const ua = getUa();

  if (get(deviceInfo, 'vendor') === 'webos') {
    console.log('Using screensaver');
    return true;
  }

  if (ua.search(WEBOS_RE) !== -1) {
    console.log('Using screensaver');
    return true;
  }

  return false;
}
