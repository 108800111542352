import React from 'react';
import { connect } from 'react-redux';
import cs from 'classnames';
import { get } from 'lodash';

import { If } from './Utils'
import { PlayersCircles } from './Players'

import {
  isRoundChanged,
  isPlayersChanged,
  isStatusChanged,
  compareKeysLength,
  diffVals
} from '../utils/game';

import {
  getCardById,
  skinStyle
} from '../utils/cards';

import {
  GAME_STATUS,
  ROTATIONS
} from './../constants';

import {
  create,
  play,
  pause,
  reverse,
  timeline,
  progress,
  clear,
  add,
  isAnimated
} from './animations';

import {
  toBoard
} from './animations/Cards';

const getVotes = (id, map) => get(map, id, []);

const SplitCards = ({children, splitCount}) => (
  <div className={cs("open-cards", `split-cards-by-${splitCount}`)}>
    {children}
  </div>
);

const Placeholder = ({position, children}) => (
  <div className={cs("card-placeholder", position)}>
    {children}
  </div>
)

class Cards extends React.Component {
  images = {}
  hiddenTimeline = isAnimated(this.props.vendorInfo) ? create(timeline, { paused: false }) : null
  openTimeline   = isAnimated(this.props.vendorInfo) ? create(timeline) : null

  componentDidMount () {
    this.switchCards()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return isRoundChanged(nextProps.round, this.props.round) ||
           isPlayersChanged(nextProps.players, this.props.players) ||
           isStatusChanged(nextProps.status, this.props.status);
  }

  componentDidUpdate (prevProps) {
    if (compareKeysLength(prevProps.round.moves, this.props.round.moves)) {
      this.preloadCards(prevProps.round.moves, this.props.round.moves);
    }

    if (isStatusChanged(prevProps.status, this.props.status)) {
      this.switchCards()
    }
  }

  preloadCards = (p, n) => {
    if (!this.props.game.deck.cards.length) return;
    diffVals(p, n).forEach((cardId) => {
      const img = new Image();
      img.src = getCardById(cardId, this.props.game.deck).skin
      this.images[cardId] = img;
    });
  }

  switchCards = () => {
    if (this.props.status === GAME_STATUS.WAIT_MOVES) {
      play(this.hiddenTimeline);
    }

    if (this.props.status === GAME_STATUS.WAIT_VOTES) {
      reverse(this.hiddenTimeline, 1.5).then(() => {
        play(this.openTimeline);
      })
    }

    if (this.props.status === GAME_STATUS.SHOW_RESULTS) {
      pause(this.hiddenTimeline);
      if (!progress(this.openTimeline)) {
        play(this.openTimeline);
      }
    }

    if (this.props.status === GAME_STATUS.SHOW_SCORE) {
      pause(this.hiddenTimeline);
      reverse(this.openTimeline, 1.5).then(() => {
        clear(this.hiddenTimeline);
        clear(this.openTimeline);
        this.props.cardsWasHidden(true);
      });
    }
  }

  renderCardsList = (arr, round, deck, voters, hidden=false) => {
    if (!arr) return null;
    const timeline = hidden ? this.hiddenTimeline : this.openTimeline
    return arr.map((move, i) => (
      <Placeholder position={`cc_${i + 1}`} key={i}>
        <Card
          player={move}
          voters={getVotes(round.moves[move], voters)}
          card={getCardById(round.moves[move], deck)}
          index={i}
          hidden={hidden}
          images={this.images}
          timeline={timeline}
          notAnimated={!timeline}
          />
      </Placeholder>
    ))
  }

  render () {
    const { round, voters } = this.props;
    const { players, deck } = this.props.game;
    return (
      <If a={round && players.length}>
        <SplitCards splitCount={(round.movesShuffled && round.movesShuffled.length) || 1}>
          {this.renderCardsList(round.movesShuffled, round, deck, voters)}
        </SplitCards>
        <div className="cards-hidden-wrapper">
          {this.renderCardsList(Object.keys(round.moves), round, deck, voters, true)}
        </div>
      </If>
    )
  }
}

const cardsState = ({game, user, ui}) => ({
  game,
  round: game.roundState,
  player: user,
  status: game.status,
  master: game.roundState.master.id,
  players: game.players,
  voters: game.roundResults,
  lastHiddenCardWasComplete: ui.lastHiddenCardWasComplete,
});

export default connect(cardsState)(Cards);


class CardElement extends React.Component {
  reference = React.createRef();

  componentDidMount () {
    add(this.props.timeline, toBoard, this.reference.current)
  }

  getSkin = (hidden, card, direction) => {
    if (!hidden) return skinStyle(get(this.props.images, `["${card.id}"].src`, card.skin), direction);
    return direction;
  }

  render () {
    const { master, hidden, voters, card, player, status, index, notAnimated } = this.props;

    return (
      <div className={cs({
        'master-card': !hidden && master === player && status === GAME_STATUS.SHOW_RESULTS,
        'open-card' : !hidden,
        'hidden-card': hidden,
        'not-animated-hide': !staticCardShouldShow(status, hidden, notAnimated),
      }, "card", 'enable-hardware-acceleration')}
        style={this.getSkin(hidden, card, { ...ROTATIONS[index], zIndex: index})}
        ref={this.reference}
      >
        <If a={!hidden && voters.length && status === GAME_STATUS.SHOW_RESULTS}>
          <PlayersCircles players={voters} limit={3}/>
        </If>

      </div>
    );
  }
}

const staticCardShouldShow = (status, hidden, notAnimated = false) => {
  if (!notAnimated) return true;
  if (hidden && status === GAME_STATUS.WAIT_MOVES) return true;
  if (!hidden && (
        status === GAME_STATUS.WAIT_VOTES ||
        status === GAME_STATUS.SHOW_RESULTS ||
        status === GAME_STATUS.CALCULATE_SCORE)) return true;
  return false;
}

const Card = connect(({game, ui}) => ({
  status: game.status,
  master: game.roundState.master.id,
}))(CardElement)
