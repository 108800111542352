export const shrink = (tl, target) => {
  if (!tl) return tl;
  tl.from(target, { x: 0, rotate: 0, delay: 0 }, 0)
  return tl
}

export const fadeInTimeline = (tl, target) => {
  if (!tl) return tl;
  return fadeIn(tl, target).fromTo('.how-to__desc, .how-to__card-img', { y: 100, opacity: 0 }, { y: 0, opacity: 1 }, 0)
}

export const fadeIn = (tl, target) => {
  if (!tl) return tl;
  tl.fromTo(target, { opacity: 0 }, { opacity: 1 }, 0);
  return tl;
}

export const fromOutside = (tl, target, position = 'relative', top = 0) => tl.from(target, { y: 320, opacity: 0 })

export const appearence = (tl, target) => tl.duration(0.5)
    .fromTo(`${target}.one`,   { x: -120, y: 160 }, {x: 0, y: 0}, 0)
    .fromTo(`${target}.two`,   { x: 0,    y: 160 }, {x: 0, y: 0}, 0)
    .fromTo(`${target}.three`, { x: 0,    y: 160 }, {x: 0, y: 0}, 0)
    .fromTo(`${target}.four`,  { x: 120,  y: 160 }, {x: 0, y: 0}, 0)
