// fallback
export const KEYCODES = {
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  RETURN: 8,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  NUMERIC_ZERO: 96,
  NUMERIC_ONE: 97,
  NUMERIC_TWO: 98,
  NUMERIC_THREE: 99,
  NUMERIC_FOUR: 100,
  NUMERIC_FIVE: 101,
  NUMERIC_SIX: 102,
  NUMERIC_SEVEN: 103,
  NUMERIC_EIGHT: 104,
  NUMERIC_NINE: 105,
  RED: 403,
  GREEN: 36,
  YELLOW: 405,
  BLUE: 406,
  PLAY: 415,
  PAUSE: 19,
  STOP: 413,
  REC: 416,
  FF: 417,
  RW: 412,
  EXIT: 27,
  INFO: 457,
  PAGE_UP: 33,
  PAGE_DOWN: 34
};

