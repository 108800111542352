import {
  createBatchRecord,
  createTransaction,
 } from './../database/game';

import {
  COLLECTION_PLAYERS,
  app as Firebase
} from '../database/common';

import {
  PALETTE,
  ICONS,
  GAME_STATUS_LOOP,
  GAME_STATUS,
  SCORE_LIMIT,
} from './../constants';

import {
  shuffle,
  times,
  zipWith,
  difference,
  differenceBy,
  filter,
  find,
  every,
  reject,
} from 'lodash';

import { logError as logKibana } from '../components/Iframe';

import {omit} from './debug';

export const compareKeysLength = (a, b) => Object.keys(a).length !== Object.keys(b).length
export const compareKeysExceeded = (a, n) => Object.keys(a).length >= n
export const diffKeys = (b, a) => difference(Object.keys(a), Object.keys(b))
export const diffVals = (b, a) => difference(Object.values(a), Object.values(b))

export const compareFalseToTrue = (prev, next) => prev !== next && prev === false && next === true;

export const isHostDevice = ({did, game}) => did === game.did

export const generateQueue = (players) => {
  const queueBatch = createBatchRecord();

  shuffle(players).forEach((p, i) => {
    queueBatch.update(p.ref, {
      queueOrder: i
    });
  });

  return queueBatch
}

export const startGame = async (game, usedDecks) => generateQueue(game.players)
  .update(game.ref, {
    status: nextStatus(game.status),
    usedDecks,
  })
  .commit();

const getWinners = gameRef => gameRef.collection(COLLECTION_PLAYERS).where('score', '>=', SCORE_LIMIT).get();

const removeInactivePlayers = (ref) => createTransaction(async (tr) => {
  try {
    const { docs } = await ref.collection(COLLECTION_PLAYERS).get();
    const removedUsers = filter(docs, (snap) => {
      const { removed } = snap.data();
      return !!removed;
    }).map(({ref}) => tr.delete(ref))
    return Promise.all(removedUsers);
  } catch (e) {
    return Promise.reject(e);
  }
})

export const sortPlayersByQueue = (players) => players.sort((a, b) => a.queueOrder - b.queueOrder)

const findNextPlayerInQueue = (players, master = { queueOrder: -1 }) => {
  const succ = find(players, ({queueOrder, removed}) => !removed && queueOrder > master.queueOrder);
  const pred = find(players, ({removed}) => !removed);
  if (!succ && !pred) return Promise.reject("No next player")
  return Promise.resolve(succ || pred);
}

export const onPlayers = (context) => ({docs}) => context.props.setPlayers(
  docs.map((player) => ({
    ...player.data(),
    ...{ ref: player.ref }
  }))
);


export function gameProcessing (context) {
  return (handler, ...args) => handler.call(context, ...args)
}

export function handleGameStarted () {
  if (!isHostDevice(this.props)) return;

  const { ref, roundState, players } = this.props.game;

  const sorted = sortPlayersByQueue(players)
  const master = find(players, ({id}) => roundState.master.id === id);
  findNextPlayerInQueue(sorted, master).then((player) => {
    removeInactivePlayers(ref)
    .then(() => getWinners(ref))
    .then((winners) => {
      if (!winners.empty)      {
        this.props.removeSession();
        return this.props.setGameStatus(ref, GAME_STATUS.FINISHED);
      }
      if (roundState.finished) this.props.setGameState({ currentRound: null });
      return this.nextRound(player);
    }).catch((err) => this.onError(this.props.game)(err))
  }).catch((err) => this.onError(this.props.game)(err))

}

export function handleCalculateScore() {
  if (!isHostDevice(this.props)) return;
  return this.calculateScore()
    .then(() => this.proceedNextState())
    .catch((err) => this.onError(this.props.game)(err))
}


export const nextStatus = (currStatus) => {
  const idx = GAME_STATUS_LOOP.indexOf(currStatus);
  if (idx === GAME_STATUS_LOOP.length - 1 || idx === -1) return GAME_STATUS_LOOP[0];
  return GAME_STATUS_LOOP[idx + 1];
}

export const isStatusRenderable = (next) => next !== GAME_STATUS.CALCULATE_SCORE && next !== GAME_STATUS.STARTED;
export const isStatusChanged = (next, prev) => next !== prev && isStatusRenderable(next);

export const isMasterChanged = (next, prev) => next !== prev;

export const isRoundChanged = (next, prev) => {
  if (!next || !prev) return false;
  if (compareKeysLength(next.moves, prev.moves)) return true;
  if (compareKeysLength(next.votes, prev.votes)) return true;
  if (next.movesShuffled !== prev.movesShuffled) return true;
  return isMasterChanged(next.master.id, prev.master.id);
}
// everybody made their move or vote
const everybodyMadeTheir = (activity, activePlayers) => every(activePlayers, ({id}) => activity[id]);

export const isAssociationPicked = (round, status) => {
  return !!round.moves[round.master.id] && status === GAME_STATUS.WAIT_ASSOCIATION;
}

export const isRoundFinished = (round, activePlayers) => {
  return !round.finished && everybodyMadeTheir(round.votes, reject(activePlayers, ({id}) => id === round.master.id));
}

export const isMovesFulfilled = (round, activePlayers, status) => {
  return !round.finished &&
         status === GAME_STATUS.WAIT_MOVES &&
         everybodyMadeTheir(round.moves, activePlayers);
}

export const isPlayersChanged = (next = [], prev = []) => next.length !== prev.length;
export const isSomebodyLeaveGame = (next = [], prev = []) => !!differenceBy(next, prev, 'removed').length

export const isMasterLeaveGame = (players, masterId) => {
  const master = find(players, ({id}) => masterId === id);
  return master && Boolean(master.removed);
}


export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  const color = times(6, () => letters[Math.floor(Math.random() * 16)]).join('')
  return `#${color}`;
}

export const getAssets = () => zipWith(shuffle(PALETTE), shuffle(ICONS), (color, icon) => ({ color, icon }))

export const arc = (rend) => (loc, rotation=0, scale=1, color='#fae') => {
  rend.noFill();
  rend.strokeWeight(W);
  rend.stroke(color);
  rend.arc(loc.x, loc.y, W*2, W*2, 0 + rotation, rend.PI + rotation);
}

const cosB = Math.cos(2 * Math.PI / 3)
const sinB = Math.sin(2 * Math.PI / 3)
const A = 2.5;
const W = 5;


export const triangle = (rend) => (center, rotation = 0, scale=1, color='#fae') => {

  const cosA = Math.cos(rotation)
  const sinA = Math.sin(rotation)


  /*
    a = -(A * cos(angle),              A * sin(angle))
    b = -(A * cos(angle + 2 * PI / 3), A * sin(angle + 2 * PI / 3))
    c = -(A * cos(angle - 2 * PI / 3), A * sin(angle-2 *   PI / 3))
  */

  let top    = [center.x - A * cosA, center.y - A * sinA]
  let left   = [center.x - A * (cosA * cosB - sinA * sinB), center.y - A * (sinA * cosB + cosA * sinB)]
  let right  = [center.x - A * (cosA * cosB + sinA * sinB), center.y - A * (sinA * cosB - cosA * sinB)]

  rend.strokeWeight(W);
  rend.stroke(color);
  rend.fill(color)
  rend.triangle(...top, ...left, ...right);
}

export const circle = (rend) => (loc, rotation=0, scale=1, color='#fae') => {
  rend.strokeWeight(W);
  rend.stroke(color);
  rend.fill(color)
  rend.ellipse(loc.x, loc.y, W);
}

export const filterRemoved = ({removed}) => !removed;

export const logAsyncError = (prefix = '') => (state) => (err) => {
  let data = {};
  try {
    data = omit(state)
  } catch (err) {
    console.log('logAsyncError:JSON', err)
  }
  return logKibana(prefix, err, data);
}


export const proceedToNextGame = async (ref, did) => {
  const { data } = await Firebase.callFn('createNewGame', { did });
  await ref.update({ nextGame: data.id });
  return data.id;
}

export const removeHttp = (text = "") => text.replace(/https?:\/\//, '');

export const setPause = async (gameId, enable, secondsLeft) => Firebase.callFn('pauseGame', { gameId, enable, secondsLeft });
