import React          from 'react';
import { withRouter } from "react-router";
import { connect }    from 'react-redux';

import { i18n as lang } from '../translations';

import {
  withFocus,
  FOCUS_ITEM,
  FOCUS_ACTIONS
} from './Focus';


import {
  RESPONSE_ERRORS
} from './../constants';

import { withIframe } from './Iframe'
import { createGame } from '../store/reducers/game/dispatchers';
import { Popup } from './Popup';

import {
  Button,
  RoundedButton
} from './Buttons';

import {
  If,
  GameVersion,
  withLoader
} from './Utils';

import {
  Background,
  StaticBackground,
  appearence
} from './animations/MenuBackground';

import { play, reverse, create, timeline, isAnimated } from './animations';

import {
  logAsyncError,
  compareFalseToTrue
} from './../utils/game';

import { setVisibleHtp } from '../store/reducers/ui';

const mapDispatchToProps = dispatch => ({
  createGame: (did) => dispatch(createGame(did)),
  openHtp: () => dispatch(setVisibleHtp(true))
});

class Menu extends React.Component {
  static displayName = 'Menu'

  onError = logAsyncError('Menu')

  state = {
   showConfirmExit: false,
   showCreateError: false,
  }

  BackgroundComponent = isAnimated(this.props.getDeviceInfo()) ? <Background /> : <StaticBackground />

  componentDidMount () {
    this.introTimeline = isAnimated(this.props.getDeviceInfo()) ? create(timeline) : null

    appearence(this.introTimeline);

    this.props.restoreSession((id) => {
      if (id) return this.props.history.push(`/lobby/${id}`);
      this.props.setActiveComponent(this);
      play(this.introTimeline);
    });
  }

  componentDidUpdate(prevProps) {
    const prevVis = prevProps.isVisibleHowToPlay;
    const nextVis = this.props.isVisibleHowToPlay;

    // replay animation when close Popup HTP
    if (compareFalseToTrue(nextVis, prevVis)) {
      play(this.introTimeline, 2);
    }
  }

  componentWillUnmount () {
    this.props.removeActiveComponent();
  }

  proceedToGame = () => reverse(this.introTimeline, 3)
    .then(() => withLoader(this.props.createGame(this.props.did)))
    .then((id) => {
      this.props.saveSession(id);
      return Promise.resolve(this.props.history.push(`/lobby/${id}`))
    }).catch((err) => {
      this.handleCreateError(err)
      this.onError(this.state)(err)
  })

  proceedToAbout = async () => {
    await reverse(this.introTimeline, 3);
    this.props.openHtp();
  }

  navigateBack = async () => {
    this.openPopup(true);
  }

  exitGame = async () => {
    this.openPopup();
    this.props.exitApp();
  }

  openPopup = async (showConfirmExit = false, showCreateError = false) => this.setState({showConfirmExit, showCreateError})

  handleCreateError = ({details}) => {
    if (!details) return;
    if (details.error === RESPONSE_ERRORS.NO_FREE_CODE) {
      // show notification here
      this.openPopup(false, true);
    }
  }

  closeTryAgainPopup = () => {
    play(this.introTimeline);
    this.openPopup();
  }

  render() {
    const { focus, useMouse, currentLayer } = this.props;
    return (
      <div className="start-page">
          {this.BackgroundComponent}
          <GameVersion />
          <div className="logo">{lang.GAME_NAME}</div>
          <div className="start-page__buttons m-top enable-hardware-acceleration">
              <Button handleAction={this.proceedToGame}
                      css={{
                        focused: focus === FOCUS_ITEM.NEW_GAME,
                        mouseHover: useMouse,
                        activeLayer: currentLayer === this
                      }}
                      text={lang.NEW_GAME}
              />
              <Button handleAction={this.proceedToAbout}
                      css={{
                        focused: focus === FOCUS_ITEM.HOW_TO_PLAY,
                        mouseHover: useMouse,
                        activeLayer: currentLayer === this
                      }}
                      text={lang.HOW_GAME}
              />
          </div>
          <If a={this.state.showConfirmExit}>
            <Popup
              buttons={[lang.BUTTON_CONTINUE_GAME, lang.BUTTON_LEAVE_GAME]}
              handlers={[this.openPopup, this.exitGame]}
              dismiss={this.openPopup}
            />
          </If>
          <If a={this.state.showCreateError}>
            <Popup
              title={lang.NO_FREE_CODE_ERROR}
              buttons={[lang.BUTTON_CONTINUE_GAME]}
              handlers={[this.closeTryAgainPopup]}
              dismiss={this.closeTryAgainPopup}
            />
          </If>
          <RoundedButton
            skin="exit"
            useMouse={useMouse}
            focus={focus}
            focusName={FOCUS_ITEM.MENU}
            handleAction={this.navigateBack}
            css={{
              focused: focus === FOCUS_ITEM.MENU,
              activeLayer: currentLayer === this,
            }}
          />
      </div>
    );
  }
}

const FOCUS_SCHEME = {
  [FOCUS_ITEM.NEW_GAME]: {
    [FOCUS_ACTIONS.UP]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.DOWN]: FOCUS_ITEM.HOW_TO_PLAY,
    [FOCUS_ACTIONS.ENTER]: 'proceedToGame'
  },
  [FOCUS_ITEM.HOW_TO_PLAY]: {
    [FOCUS_ACTIONS.UP]: FOCUS_ITEM.NEW_GAME,
    [FOCUS_ACTIONS.LEFT]: FOCUS_ITEM.MENU,
    [FOCUS_ACTIONS.ENTER]: 'proceedToAbout'
  },
  [FOCUS_ITEM.MENU]: {
    [FOCUS_ACTIONS.RIGHT]: FOCUS_ITEM.NEW_GAME,
    [FOCUS_ACTIONS.DOWN]:  FOCUS_ITEM.NEW_GAME,
    [FOCUS_ACTIONS.ENTER]: 'navigateBack'
  },
}

const mapStateToProps = ({ui}) => ({
  isVisibleHowToPlay: ui.isVisibleHowToPlay
})

export default withIframe(
               withFocus(FOCUS_SCHEME)(
               withRouter(
               connect(mapStateToProps, mapDispatchToProps)(Menu))));
