export const errorToJsonPolyfill = () => {
  if (!('toJSON' in Error.prototype)) {
    /* eslint-disable-next-line */
    Object.defineProperty(Error.prototype, 'toJSON', {
      value: function () {
        const alt = {};

        Object.getOwnPropertyNames(this).forEach(function (key) {
          alt[key] = this[key];
        }, this);

        return alt;
      },
      configurable: true,
      writable: true
    });
  }
}

const json = (data) => {
  let cache = [];
  const v = JSON.stringify(data, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
    }
    if (typeof value === 'function') {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
      return value.toString();
    }
    return value;
  });
  cache = null;
  return v;
};

export const omit = data => JSON.parse(json(data));
