import React from 'react';
import { GAME_NAME } from '../translations';

export const Splash = ({centered}) => (
  <div className={"splash__title logo" + (centered ? " splash" : "")}>
    <div className="splash__box-wrap">
      <div className="splash__box one"  />
      <div className="splash__box two"  />
      <div className="splash__box three"/>
      <div className="splash__box four" />
    </div>
    <div className="splash__title_index">{GAME_NAME}</div>
  </div>
)
