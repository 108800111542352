import 'core-js/es';
import 'core-js/es/set';
import 'core-js/es/map';
import 'core-js/es/array';
import 'raf/polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { store } from './store';

import App from './App';
import HowToPlay from './components/HowToPlay';
import Focus  from './components/Focus';
import {Splash} from './components/Splash';
import {PopupPageLayer, Loader} from './components/Utils';

import { init as initFirebase } from './database/common';

import { errorToJsonPolyfill } from './utils/debug';
import { IframeProvider, withIframe } from './components/Iframe';

import { version } from '../package.json'
import { USE_SPLASH, SPLASH_DELAY } from './constants';

if (process.env.REACT_APP_USE_HW_ACCELERATION === 'true') {
  require('./hw.css');
}

errorToJsonPolyfill()

const Error = () => <div> Error! App version: {version}. Build: {process.env.REACT_APP_TIMESTAMP} </div>

class Application extends React.Component {
  state = {
    ready: false,
    error: false
  }

  componentDidMount () {
    initFirebase().then(() => {
      console.log(
      `
       App version: ${version}
       Build: ${process.env.REACT_APP_TIMESTAMP || 2814}
       Env: ${process.env.REACT_APP_ENV}
       Locale: ${this.props.locale}
       Geo: ${this.props.geo}
       Did: ${this.props.did}
       Screen: ${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}
      `)
      if (USE_SPLASH) {
        setTimeout(() => this.setState({ready: true}), SPLASH_DELAY);
      } else {
        this.setState({ready: true})
      }
    }).catch((err) => {
      this.setState({error: true});
      this.props.sendError('Init firebase', err);
      this.props.exitApp();
    })
  }

  render () {
    if (this.state.error) return <Error />;
    if (!this.state.ready) return (
      <PopupPageLayer show>
        <Splash centered />
      </PopupPageLayer>
    );
    return (
      <Provider store={store}>
        <Focus>
          <App />
          <HowToPlay />
          <Loader />
        </Focus>
      </Provider>
    );
  }
}

const Entry = withIframe(Application);

ReactDOM.render(<IframeProvider env={process.env.REACT_APP_ENV} version={version}><Entry/></IframeProvider>, document.getElementById('root'));

