import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

import { firebaseConfigEnv } from './configEnv';

export let app: Firebase;

export const COLLECTION_GAMES   = "games";
export const COLLECTION_ROUNDS  = "rounds";
export const COLLECTION_ERRORS  = "errors";
export const COLLECTION_PLAYERS = "players";

export const init = () => {
  if (!process.env.REACT_APP_ENV) {
    return Promise.reject();
  }

  if (!firebase.apps.length) {
    app = new Firebase(firebase.initializeApp(firebaseConfigEnv));
  } else {
    app = new Firebase(firebase.apps[0]);
  }
  return Promise.resolve()
}

type GameParams = {
  code?: string;
  gameId?: string;
  username?: string;
  playerId?: string;
  did?: string;
}

class Firebase {
  app: firebase.app.App;

  constructor(app: firebase.app.App) {
    this.app = app;
  }

  callFn = (name: string, args: AtLeastOne<GameParams> = {}) => this.app.functions(process.env.REACT_APP_REGION).httpsCallable(name)(args)
  getFirestore = (): firebase.firestore.Firestore => this.app.firestore();

  logError = (fn: (doc: firebase.firestore.DocumentReference) => void) => fn(this.getFirestore().collection(COLLECTION_ERRORS).doc())
}
